._arrivaldatepicker_ .dropdown {
  box-shadow: none !important;

  .dropdown-item {
    background-color: transparent !important;
    padding: 0 !important;
  }

  #dropdown-custom-components {
    border: 1px solid #dee2e6 !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 1px 5px 1px 10px !important;
  }
}

.arrivaldatepicker .light .DateRangePicker__Date {
  color: #9c9a9ade;
}

.arrivaldatepicker .instantlyBookable .DateRangePicker__Date {
  color: black;
}

.arrivaldatepicker {
  // box-shadow: -2px 5px 10px rgba(82, 80, 80, 0.309);
  // width:50%;
  // box-shadow: 0px 2px 15px black;
  // margin-top: 53px;
  // border-radius: 5px;
  // overflow-y: auto;
  // position: absolute;
  // z-index: 4;
  // min-width: 100%;
  // margin-left: -0.3em;
  .height {
    min-height: 20px;
    max-height: 340px;
  }

  .data-row:hover {
    background-color: #3165ba;
  }

  // .DateRangePicker__Month {
  //   width: 260px;
  // }
  // border: none !important;

  .DateRangePicker__Date {
    padding: 12px 8px !important;
    border: none !important;
    color: #9c9a9ade;
  }

  .DateRangePicker__Date--is-selected {
    color: black !important;
  }

  .DateRangePicker__CalendarHighlight--single {
    border: 1px solid #3165ba !important;
  }

  .DateRangePicker__Month {
    width: 260px;

    .DateRangePicker__CalendarSelection--start {
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
    }

    .DateRangePicker__CalendarSelection--end {
      border-bottom-right-radius: 16px;
      border-top-right-radius: 16px;
    }

    .DateRangePicker__MonthHeader {
      color: #222222 !important;
      font-weight: 600 !important;
    }

    .DateRangePicker__Date--weekend {
      background-color: #fff !important;
    }

    .DateRangePicker__Weekdays {
      background-color: #dcdcdc8c !important;
    }

    .DateRangePicker__WeekdayHeading abbr[title] {
      font-weight: 600 !important;
      color: #0202029e !important;
    }
  }

  .DateRangePicker__HalfDateStates {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: none;
    width: 100px !important;
  }

  .DateRangePicker__CalendarDatePeriod--pm {
    left: 0;
  }

  .DateRangePicker__CalendarDatePeriod--am {
    right: 0;
  }

  .DateRangePicker__CalendarSelection {
    background-color: transparent;
    border: 1px solid transparent;
  }

  .DateRangePicker__CalendarSelection--is-pending {
    // background-color: #85aeeeec;
    background-color: #fff;
  }

  .DateRangePicker__MonthDates {
    border-bottom: none !important;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
  .arrivaldatepicker {
    // max-width: 92% !important;
    .height {
      max-height: 410px;
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (min-width: 320px) and (max-width: 400.98px) {
  .arrivaldatepicker {
    // max-width: 93% !important;
    .height {
      max-height: 397px;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 400.98px) and (max-width: 767.98px) {
  .arrivaldatepicker {
    // max-width: 94% !important;
    .height {
      max-height: 490px;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

.search-suggestion {
  box-shadow: -2px 5px 10px rgba(82, 80, 80, 0.17);
  border-radius: 5px;
  overflow-y: auto;
  position: absolute;
  z-index: 4;
  width: 39%;

  .height {
    min-height: 20px;
    max-height: 340px;
  }

  .data-row:hover {
    background-color: #3165ba;
  }
}

._datepicker_ .dropdown {
  box-shadow: none !important;

  .dropdown-item {
    background-color: transparent !important;
    padding: 0 !important;
  }

  #dropdown-custom-components {
    border: 1px solid #dee2e6 !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 1px 5px 1px 10px !important;
  }

  .datepicker {
    .DateRangePicker__Date {
      padding: 12px 8px !important;
      border: none !important;
    }

    .DateRangePicker__CalendarHighlight--single {
      border: 1px solid #3165ba !important;
    }

    .DateRangePicker__Month {
      width: 260px;

      .DateRangePicker__CalendarSelection--start {
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
      }

      .DateRangePicker__CalendarSelection--end {
        border-bottom-right-radius: 16px;
        border-top-right-radius: 16px;
      }

      .DateRangePicker__MonthHeader {
        color: #222222 !important;
        font-weight: 600 !important;
      }

      .DateRangePicker__Date--weekend {
        background-color: #fff !important;
      }

      .DateRangePicker__Weekdays {
        background-color: #dcdcdc8c !important;
      }

      .DateRangePicker__WeekdayHeading abbr[title] {
        font-weight: 600 !important;
        color: #0202029e !important;
      }
    }

    .DateRangePicker__HalfDateStates {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: none;
      width: 100px !important;
    }

    .DateRangePicker__CalendarDatePeriod--pm {
      left: 0;
    }

    .DateRangePicker__CalendarDatePeriod--am {
      right: 0;
    }

    .DateRangePicker__CalendarSelection {
      background-color: #85aeeeec;
      border: 1px solid #85aeeeec;
    }

    .DateRangePicker__Date--is-selected {
      color: black;
    }

    .DateRangePicker__CalendarSelection--is-pending {
      background-color: #85aeeeec;
    }

    .DateRangePicker__MonthDates {
      border-bottom: none !important;
    }
  }
}
