.addlist-radio {
  .form-check-input:checked {
    background-color: #3165ba !important;
    border-color: #0d6efd !important;
  }
}

// 1024 px
.media-addlist {
  @media only screen and (max-width: 1440px) {
    margin-left: -50px;
  }
}

.placeholdeaddlist::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  font-weight: bold;
}

.placeholdertitle::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  // font-weight: bold;
  opacity: 0.5 !important;
  // padding-top: 18px;
  font-size: 12px;
}

.placeholderlocation::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  font-size: 11px !important;
  //   align-items: center !important;
  opacity: 0.5 !important;
  //   color: red !important;
}

.placeholderIternary::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  opacity: 0.5 !important;
  font-size: 11px;
  //   color: red !important;
}

.roundedBorderIternary {
  border-radius: 0.25rem !important;
}

.textcolor {
  color: #3165ba;
}

.lihome {
  li {
    // list-style-type: none;
    position: relative;
    /* It's needed for setting position to absolute in the next rule. */
  }

  li::before {
    // content: "■";
    position: absolute;
    left: -2.1em;
    /* Adjust this value so that it appears where you want. */
    font-size: 4px;
    /* Adjust this value so that it appears what size you want. */
    top: 7px;
  }
}

//
.listItems {
  border-radius: 0.25rem;
  @media only screen and (min-width: 993px) {
    border-radius: 0px;
  }
}

.roundedborderaddlist {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
  border-bottom: 0px !important;
}

// rouunder border bottom
.roundedborderaddlistbottom {
  border-bottom-left-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

// Checkbox
// @import "../utils/variables";
// Checkmark iternary
.container1 {
  display: block !important;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 120px;
  background-color: none;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container1:hover input ~ .checkmark1 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container1 input:checked ~ .checkmark1 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark1:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Checkmark retreat-calendar 1
.container2 {
  display: block !important;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark2 {
  position: absolute;
  top: 0;
  left: 12px;
  height: 20px;
  width: 20px;
  background-color: none;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container2:hover input ~ .checkmark2 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked ~ .checkmark2 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container2 input:checked ~ .checkmark2:after {
  display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark2:after {
  // left: 7px;
  // top: 3px;
  // width: 5px;
  // height: 10px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
}

// 22
// Checkmark iternary
.container1 {
  display: block !important;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: none;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container1:hover input ~ .checkmark1 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container1 input:checked ~ .checkmark1 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark1:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Checkmark retreat-calendar 1
.container2 {
  display: block !important;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark2 {
  position: absolute;
  top: 0;
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: none;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container2:hover input ~ .checkmark2 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked ~ .checkmark2 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container2 input:checked ~ .checkmark2:after {
  display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark2:after {
  // left: 7px;
  // top: 3px;
  // width: 5px;
  // height: 10px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
}

//three
.container3 {
  display: block !important;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark3 {
  position: absolute;
  top: 0;
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: none;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container2:hover input ~ .checkmark3 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container3 input:checked ~ .checkmark3 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark3:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container3 input:checked ~ .checkmark3:after {
  display: block;
}

/* Style the checkmark/indicator */
.container3 .checkmark3:after {
  // left: 7px;
  // top: 3px;
  // width: 5px;
  // height: 10px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
}

//four
.container4 {
  display: block !important;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container4 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark4 {
  position: absolute;
  top: 0;
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container4:hover input ~ .checkmark4 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container4 input:checked ~ .checkmark4 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark4:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container4 input:checked ~ .checkmark4:after {
  display: block;
}

/* Style the checkmark/indicator */
.container4 .checkmark4:after {
  // left: 7px;
  // top: 3px;
  // width: 5px;
  // height: 10px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
}

// Five
.container5 {
  display: block !important;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container5 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark5 {
  position: absolute;
  top: 0;
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: #eee;
  opacity: 0.5;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container5:hover input ~ .checkmark5 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container5 input:checked ~ .checkmark5 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark5:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container5 input:checked ~ .checkmark5:after {
  display: block;
}

/* Style the checkmark/indicator */
.container5 .checkmark5:after {
  // left: 7px;
  // top: 3px;
  // width: 5px;
  // height: 10px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
}

.quillrounded {
  border-radius: 0.3rem !important;
}

//Retreat calendar
.welcome-barretreat {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 2px;
    left: 10px;

    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #E7E6E7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

.welcome-barretreat1 {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 2px;
    opacity: 0.5;

    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #E7E6E7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    // background-color: #3165BA;
    // border: 1px solid #3165BA;
  }
}

// My-profile-Checkmark
// 22
// Checkmark iternary
.container7 {
  display: block !important;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  margin-top: -10px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container7 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark7 {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: none;
  border: 1px solid #ced4da !important;
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.container7:hover input ~ .checkmark7 {
  background-color: none;
}

/* When the checkbox is checked, add a blue background */
.container7 input:checked ~ .checkmark7 {
  background-color: #3165ba;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark7:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container7 input:checked ~ .checkmark7:after {
  display: block;
}

/* Style the checkmark/indicator */
.container7 .checkmark7:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.retreatCaledar__container {
  // border: none !important;

  .DateRangePicker__Date {
    padding: 16px 8px !important;
    border: none !important;
  }

  .DateRangePicker__CalendarHighlight--single {
    border: 1px solid #3165ba !important;
  }

  .DateRangePicker__PaginationArrow--previous,
  .DateRangePicker__PaginationArrowIcon--next {
    display: none;
  }

  .DateRangePicker__MonthDates {
    border-bottom: none !important;
  }

  .DateRangePicker__Month {
    margin: 0 10px 10px 0;
    width: 48%;
    border: solid 0.5px #dcdcdcba !important;
    border-radius: 4px;

    .DateRangePicker__CalendarSelection--start {
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;
    }

    .DateRangePicker__CalendarSelection--end {
      border-bottom-right-radius: 16px;
      border-top-right-radius: 16px;
    }

    .DateRangePicker__MonthHeader {
      color: #222222 !important;
      font-weight: 600 !important;
    }

    .DateRangePicker__Date--weekend {
      background-color: #fff !important;
    }

    .DateRangePicker__Weekdays {
      background-color: #dcdcdc8c !important;
    }

    .DateRangePicker__WeekdayHeading abbr[title] {
      font-weight: 600 !important;
      color: #0202029e !important;
    }
  }

  .DateRangePicker__HalfDateStates {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: none;
    width: 100px !important;
  }

  .DateRangePicker__CalendarDatePeriod--pm {
    left: 0;
  }

  .DateRangePicker__CalendarDatePeriod--am {
    right: 0;
  }

  .DateRangePicker__CalendarSelection {
    // background-color: #3165BA;
    background-color: #85aeeeec;
    border: 1px solid #85aeeeec;
    // border: 1px solid #3165BA;
  }

  .DateRangePicker__Date--is-selected {
    // color: #fff;
    color: black;
  }

  .DateRangePicker__CalendarSelection--is-pending {
    // background-color: #3165BA;
    background-color: #85aeeeec;
    // border-bottom-left-radius: 10px;
    // border-top-left-radius: 10px;
  }

  .DateRangePicker__CalendarSelection--end {
    // border-bottom-right-radius: 10px;
    // border-top-right-radius: 10px;
  }
}

.calanderBoxMobile {
  @media screen and (max-width: 1062px) {
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .retreatCaledar__container_main {
    height: 300px;
    overflow-y: scroll;
    position: relative;

    .retreatCaledar__container_main {
      -ms-overflow-style: none !important;
      /* Internet Explorer 10+ */
      scrollbar-width: 0 !important;
      /* Firefox */
    }

    .cretreatCaledar__container_main::-webkit-scrollbar {
      display: none !important;
      /* Safari and Chrome */
    }

    .selection {
      position: absolute;
      bottom: 0;
      // bottom: 0px;
      // left: 13px;
      // z-index: 9999;
      // width: auto;
      // right: 13px;
      // border-top: 1px solid #dee2e6 !important;
    }
  }
}

@media screen and (max-width: 1025px) {
  .retreatCaledar__container {
    .DateRangePicker__PaginationArrow--previous,
    .DateRangePicker__PaginationArrowIcon--next {
      display: block !important;
    }

    .DateRangePicker__Month {
      width: 100% !important;
    }
  }
}

.addlistprice {
  font-family: AirbnbCerealApp;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--brownish-grey);
}

.quill-faq-container {
  height: 250px;
}

.faq-readmore-height {
  height: 2.4rem;
  overflow: hidden;
}

.faq-readless-height {
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.required-badge {
  color: #1055c6 !important;
  background: #c1d1ea !important;
  font-size: 0.75em;
  border-radius: 50px;
  padding: 0.1em 0.65em;
}

.add_your_own_icon {
  position: absolute;
  margin-top: -9px;
  margin-left: -5px;
  z-index: 99;
}

input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.time {
  position: relative;
}

.arrival-info {
  .time::-webkit-calendar-picker-indicator {
    width: 17px;
    height: 17px;
    opacity: 0.5;
    padding: 0%;
    cursor: pointer;
    // background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEr0lEQVR4nO2af2hVZRjHP88550I/zNrCiLaUpBYkJYYWNmtFUU3UchPJzGGJs1oRhRDDJkWaYLAERdugZG70hwyj1BY0Y6VWWCEUEqbVH1v900hI/8ndc57+uLvRLtvded9zzr2i5wOXcc7e9/k+93mf57nnPedASkpKSsqli5TbgZkv6fTsCFsR6oDrLc2cVej3Alp/2yUnTSaWNQA3Pae3InwNVMRiUPjLdZh9ersMhZ3ixSJsiSdsUaUC6HOUtb6DI0o3UCfQdWqXrA5j5+YXtZosnSj1BLQDy8P64Ni5Hg+uz4NeAIzQ/PO78vsvO2XQ8XnWC8ANeDSsndPbZegyYZ0XgBfwiIkPViUwa50uJuAVhHnAlTY2QqAnOsVogWY1qwKc6JTQ38u4BO5Yq2/h0wqAms42wnhx3MBcxCgAd67RxRrQCpwXaFOl5/j78oe5bHHmrFGr0LoWs4wC4ObSHhE2HntPtprLhXTKMrNs5pkGYC4CrkM3wN2rdaEoHQjV5tJFsEhlKEEJuDAFhaOjaZ9ROhSqE+4FoSlFCYzBCXIr/2V3+K4bhvtWWfaApDOgsMZsa9VUJ8l5kTLAJuI2OknOMwuAFj+OC1u7ifeAtATSEih+nGfDYT2KcE9Is0c2L5B7w9htXK6Po6xSUFW6P+yVj8LMK0YiGVDph/7yAAsm0wFY3qgt6rPjf6caly3Tlt5e2TmZP8Uw2m15OrbOCo/zVI6YfSbTWdmgta7yTv68B695imYCtq1s0NrJ/Cn6nUwGhy2Ba7NmToyjcxa46skGnRFkGRFlrwuZ/P979snmp5bqNcB6YO8TS3Se45Fxcv78baKVTAlEDYDPAYQVLvyAAAFTgQHg/vyYoTO0zria9cANrvATfm77rHDARCuRDKjMcoRxansCDheeuMKn5R+XCsjdFVL4zHdZ4fkM58cMDEj2mSX/OTB19G9f4NASUhcwvQ4Iih/nuW3p2K5uyq6Dcgaof7pep0mGzO6Pc5uv5kVjI57XH/G5LuOhnftluNDWZFyQV4J5dvfJn2H0OwvGmZBIAF5YaLab2/FJuN1kEguQSAlMdD4qYfWNbJoMDrsC2z6N9/6Aqb4JF+ReoJT6iWRA60N2d3QK2dI/NpPKngFpDwi5Am9+frH2gBJfB5RCP22CRoNLdEuslPppBhg5cKn3gHFScAio3jE/nt/9SVFAGJzIHxuiPRpTmkXpAG6M7kooBsWheSJ/bIhUAs9/I33A9Ohu2FH2n8Fy0jVfq7Lj3FA1JVIPKCd6nqY4XnGLVALloGu2VrkeTSivx2HPqgQ+mKN1orwKzAWmxeGIEX58puwyQBmIzwVjhoHvRXlbhf6oxmy3w+cF2hyPnseOx/+WWFj23R69Jq0yQISNS35M7i2xsJRtO+z57IkuHZ04AmD2cDTgnBeAE5T/Nfv9NVo1+m6w0bPAQowC4CrfugoiNEURjYPLHZrc3NPg76LYMWuCPu0qPAC8cahG8YU9D58sbRM8VKNVAk0Eo9cBSnsUe8ap/MUtugnYEEU0RjbVnZK2KAasavmrmbpIhZeBu4ApURyw4BxwDKW99lc5WGLtlJSUlJSLin8BxSm4QhC/BVoAAAAASUVORK5CYII=");
  }
}

.itinerary {
  .time::-webkit-calendar-picker-indicator {
    margin-top: -5%;
    width: 17px;
    opacity: 0.5;
    cursor: pointer;
    background: url("/images/icons8-expand-arrow-24.png") !important;
  }
}

// ._draggable_{
//   div{
//     display: flex ;
//     flex-wrap: wrap;
//     // background:red;
//   }
// }
