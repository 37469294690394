.earning-card {
  align-items: center;
  // width: 11rem;
  margin: 2px;
}

.light-black {
  color: #4d4663;
}

.black {
  color: #2b2b2b;
}

.f-blue {
  color: #3165ba;
}

.kOQfTy {
  font-size: 12px;
  font-weight: 500;
}

.fBaaVY {
  font-weight: 500;
}

.earningrepo_datepicker {
  .react-date-picker__wrapper {
    border: none !important;
    border-radius: 5px;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 13px;
    //min-width:8rem;
    padding: 6px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.allpayament-select,
.admin_retreats,
.admin-bookings,
.admin-review {
  .css-1hb7zxy-IndicatorsContainer {
    align-self: baseline;
    overflow: hidden;
  }
}

.table-pagination {
  nav div:first-child {
    border: 1px solid black;
  }
}

// @media only screen and (min-width: 900px) and (max-width: 1300px) {
//   .earning-card {
//     align-items: center;
//     // width: 10rem;
//     margin: 2px;
//   }
// }

// @media only screen and (min-width: 600px) and (max-width: 900px) {
//   .earning-card {
//     align-items: center;
//     // width: 13rem;
//     margin: 2px;
//   }

//   .earning_card_div {
//     margin-top: 15px;
//   }
// }

// @media only screen and (min-width: 900px) and (max-width: 963px) {
//   .earning_card_div1 {
//     margin-top: 15px;
//   }
// }
