.sidebar {
  // margin-top: 2.8rem;
  display: block;
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  // max-height: 100%;
  height: "auto";
  // overflow-y: auto;
  width: 100%;
  box-shadow: -4px 7px 7px 4px rgb(21 34 50 / 25%);
  @include transition(max-width 0.3s);

  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 260px;
  }

  .nav {
    white-space: nowrap;
  }

  .header-logo {
    box-shadow: 0 0.5px 2px 0 rgba(21, 34, 50, 0.08);
    vertical-align: middle;
    // padding: 0.55rem 0.75rem;
    // border-radius: 12px;
    color: #222222;

    .header-icon {
      padding: 0 1.3rem;

      img {
        // height: 50px;
        width: 42px;
      }
    }

    .header-logo-expanded {
      img {
        padding: 0 1.3rem;
      }
    }
  }

  .nav-item {
    font-weight: 500;

    .header-logo {
      vertical-align: middle;
      // padding: 0.55rem 0.75rem;
      // border-radius: 12px;
      color: #222222;
      // border: 0.0625rem solid lightgrey;
    }

    border: $border-width solid transparent;

    &.active {
      & > .nav-link {
        color: $light;
        background-color: #2e3650;
        border: $border-width solid lightgrey;
      }
    }

    .nav-link {
      color: #171725;

      // border: $border-width solid lightgrey;
      &:hover,
      &:focus {
        color: #fff;
        background-color: $medium-blue;

        img {
          filter: invert(175%) sepia(30%) saturate(2%) hue-rotate(200deg)
            brightness(220%) contrast(100%) !important;
        }
      }

      &.active {
        color: black;
      }
    }

    margin-bottom: 0.2rem;
  }

  .nav-link + .multi-level {
    margin-top: 0.2rem;
  }

  .nav-link {
    font-size: 15px;
    vertical-align: middle;
    padding: 1rem 0.8rem 1rem 1.75rem;
    // @include border-radius($border-radius);
    font-weight: 500;

    .sidebar-icon {
      color: rgb(255, 255, 255);
      margin-right: 0.5rem;

      span {
        min-width: 22px;
      }

      svg {
        color: #ffff;
        min-width: 22px;
      }

      &.svg-icon {
        margin-right: 14px;
      }
    }

    .link-arrow {
      font-size: $font-size-sm;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.1rem rgba(#4c5680, 0.2);
    }
  }

  .nav-link.active {
    color: $primary;
  }

  // .multi-level {
  //   .nav-link {
  //     padding-left: 45px;
  //   }
  // }

  .sidebar-text {
    margin: auto 0;
  }

  // .sidebar-text:hover {
  //   color: white !important;
  // }

  .sidebar-text,
  .link-arrow,
  .badge,
  .notification-count {
    opacity: 1;
    @include transition(opacity 0.3s);
  }

  @include media-breakpoint-up(md) {
    .sidebar-text-contracted {
      display: none;
      @include transition(opacity 0.3s);
    }

    &.contracted {
      .sidebar-text,
      .link-arrow,
      .badge {
        opacity: 0;
      }

      .notification-count {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 40px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 0.7rem;
        padding: 0.2rem;
      }

      .nav-item {
        position: relative;

        &.active {
          & > .nav-link {
            background-color: $primary;
          }
        }

        .nav-link {
          &:hover {
            background-color: $primary;
          }
        }
      }

      .sidebar-text-contracted {
        display: inline;
      }

      // .multi-level .nav-link {
      //   padding-left: 17px;
      // }

      .nav-item {
        white-space: nowrap;
      }

      .sidebar-icon {
        text-align: center;
      }

      @include media-breakpoint-up(md) {
        & + .content {
          margin-left: 95px;
        }
      }

      max-width: 95px;
    }
  }
}

.sidebar-inner {
  position: relative;
  overflow: hidden;
  background-color: #ffff;

  .accordion__navadmin {
    .accordion-item {
      border: none !important;
    }

    .accordion-button {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-angle-down fa-w-10 fa-2x'%3E%3Cpath fill='currentColor' d='M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z' class=''%3E%3C/path%3E%3C/svg%3E") !important;
        // content: "\f054";
        background-size: 0.75rem !important;
        filter: invert(59%) sepia(15%) saturate(596%) hue-rotate(193deg)
          brightness(87%) contrast(84%);
      }

      &:not(.collapsed) {
        background-color: $medium-blue !important;
        font-weight: bold;
        color: #ffff !important;

        &::after {
          filter: invert(99%) sepia(30%) saturate(2%) hue-rotate(252deg)
            brightness(107%) contrast(100%);
          transform: rotate(90deg);
          transition: all 0.2s ease;
        }
      }

      &:hover,
      &:focus {
        &::after {
          filter: invert(99%) sepia(30%) saturate(2%) hue-rotate(252deg)
            brightness(107%) contrast(100%);
        }

        background-color: $medium-blue !important;
        font-weight: bold;
        color: #ffff !important;
        border-radius: 0 !important;
      }
    }

    padding-top: 0.25rem;

    .accordion-body {
      padding: 0.5rem 0 0 0;
    }

    .accordion-collapse {
      border: 0;
    }
  }
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.user-card {
  border-bottom: 0.0625rem solid #2e3650;
}

@include media-breakpoint-down(sm) {
  .sidebar {
    width: 100%;
  }
}

.content {
  overflow: visible;
  // padding: 0 $spacer 0 $spacer;
  @include transition(all 0.3s);

  @include media-breakpoint-up(md) {
    margin-left: 260px;
  }
}

.sidebar-toggle {
  font-size: 1.3rem;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background: white !important;
  }
}

.sidebar-transition-enter {
  opacity: 0;
}

.sidebar-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.sidebar-transition-exit {
  opacity: 1;
}

.sidebar-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

/**
 * = Sidebars
 */

#doc-index:not(.collapse.show),
.doc-sidebar {
  display: none;
}

@include media-breakpoint-up(lg) {
  #doc-index {
    display: block;
  }

  .doc-sidebar {
    display: block;
    height: calc(100vh - 2rem);
    overflow-y: scroll;
    position: -webkit-sticky;
    position: sticky;
    top: 2rem;

    .nav-link[data-toggle="collapse"] > .icon {
      transform: rotateZ(-90deg);
      position: relative;
      right: 0.25rem;
    }

    .nav {
      .nav-item {
        font-size: $font-size-sm;
      }
    }
  }
}

.toggle-admin {
  background-color: #ffffff !important;
  color: #222222 !important;
}

.toggle-admin :hover {
  background-color: #ffffff !important;
  color: #222222 !important;
}

.toggle-admin :active {
  background-color: #ffffff !important;
  color: #222222 !important;
}

.toggle-admin :focus {
  background-color: #ffffff !important;
  color: #222222 !important;
}

.admin-main__container {
  background-color: #f4f7fc;
}
