.booked-message {
  color: #18ad18 !important;
  border: 1px solid #18ad18 !important;
}

.booked-notavilable {
  border: 1px solid #ccad2f !important;
  color: #ccad2f;
}

.messages__chatContainer {
  // height: 60vh;
  // overflow-y: scroll;
  // padding: 5px;
}

.messages__chatContainer::-webkit-scrollbar {
  width: 0.5em;
}

.messages__chatContainer::-webkit-scrollbar-thumb {
  background-color: rgba(139, 139, 139, 0.836);
  border-radius: 55px;
}

.messages__listMessages {
  height: 100vh;
  overflow-y: auto;
  // width: 33%;
}

.messages__listMessages::-webkit-scrollbar {
  width: 0.2em;
}

.messages__listMessages::-webkit-scrollbar-thumb {
  background-color: rgba(139, 139, 139, 0.616);
  border-radius: 55px;
}