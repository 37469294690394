.user-datepicker-dropdown {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    option {
      background-color: white;
      display: none;
    }
  }
}

.verify-button {
  .form-switch {
    padding-left: 1.8em !important;
  }
}

.user-datepicker {
  .DateRangePicker__CalendarHighlight--single {
    border: 1px solid #85aeeeec !important;
  }
}
