.image-wishlist-container {
  position: relative;
  width: 100%;
}

.image-wishlist {
  width: 100%;
  height: 100%;
}

.fa-heart-wish-container {
  position: relative;
  right: 7px;
  top: 7px;

  .fa-heart-wish {
    position: absolute;
    z-index: 1;
    right: 0;
    display: block;
    color: #3165ba;
  }
}

.fa-heart-wish-listing {
  position: absolute;
  z-index: 2;
  color: white;
}

.fa-tag {
  text-shadow: 0px 0px 1px red;
}

.outline-font {
  -webkit-text-stroke: 1px red;
}

.like-button {
  position: absolute;
  z-index: 2;
  color: whitesmoke;
  font-size: 20px;
  text-align: right;
  cursor: pointer;
  border-radius: 10rem;
  background-color: transparent;
  border: transparent;
  width: 99%;
  padding-top: 4px;
  padding-right: 4px;
  top: 3px;
  right: 25px;
}

.wishlist-item {
  position: relative;
  //padding-top: 20px;
  display: inline-block;
}

.wishlis-notify-badge {
  position: absolute;
  right: 5.5px;
  top: 3px !important;
  background: #3165ba;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-size: 9px;
  min-width: 17px;
  min-height: 17px;
  font-weight: 700;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .wishlis-notify-badge {
    position: absolute;
    right: 5.5px;
    top: -3px;
    font-size: 9px;
    min-width: 17px;
    min-height: 17px;
  }
}

@media (max-width: 991px) {
  .wishlis-notify-badge {
    position: absolute;
    // right: -5px;
    left: 20px;
    top: -7px !important;
    font-size: 10px;
    min-width: 19px;
    min-height: 19px;
  }
}
