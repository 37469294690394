.placeholderpayment::placeholder {
  /* color: red; */
  opacity: 0.5; /* Firefox */
}
// Border payment
.borderPayment {
  border: "0.6px solid #3165BA !important";
}
.paymentopacity {
  opacity: 1;
}
.roundedBorder {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounderBorderBottom {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.paraheading {
  padding-bottom: 0.25rem !important;
}

//Radio button
.welcome-bar22 {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 2px;

    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #E7E6E7;
    border-radius: 50%;
  }
  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

// Navbar
.mobilenav {
  @media only screen and (max-width: 425px) {
    margin-top: 10px;
    width: 100px;
    height: 100px;
  }
}
.tabletnav {
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    width: 100px;
    height: 100px;
  }
}

// Payment options
.margintoppayment {
  @media only screen and (max-width: 375px) {
    margin-top: 13.5px;
  }
}

.margintoppaymentsmall {
  @media only screen and (max-width: 320px) {
    margin-top: 13.5px;
  }
}

.bookingform_datepicker {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 3px;
    background-color: white;
    width: auto;
    height: 40px;
    @media only screen and (max-width: 320px) {
      width: 14rem !important;
    }
    @media only screen and (max-width: 375px) and (min-width: 321px) {
      width: 17.2rem !important;
    }
    @media only screen and (max-width: 375px) and (min-width: 321px) {
      width: 17.2rem !important;
    }
    @media only screen and (max-width: 425px) and (min-width: 376px) {
      width: 20.5rem !important;
    }
  }

  .react-date-picker__inputGroup {
    font-size: 13px;
    width: 14rem;
    padding: 6px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    option {
      background-color: white;
      display: none;
    }
  }
  @media only screen and (max-width: 320px) {
    width: 14rem !important;
  }
  @media only screen and (max-width: 375px) and (min-width: 321px) {
    width: 17.2rem !important;
  }
  @media only screen and (max-width: 375px) and (min-width: 321px) {
    width: 17.2rem !important;
  }
  @media only screen and (max-width: 425px) and (min-width: 376px) {
    width: 20.5rem !important;
  }
}

.addInstructor_datepicker {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 3px;
    background-color: white;
    width: auto;
    height: 33px;
    @media only screen and (max-width: 320px) {
      width: 14rem !important;
    }
    @media only screen and (max-width: 375px) and (min-width: 321px) {
      width: 17.2rem !important;
    }
    @media only screen and (max-width: 375px) and (min-width: 321px) {
      width: 17.2rem !important;
    }
    @media only screen and (max-width: 425px) and (min-width: 376px) {
      width: 20.5rem !important;
    }
  }

  .react-date-picker__inputGroup {
    font-size: 12px;
    width: 11.5rem;
    padding: 4px;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    option {
      background-color: white;
      display: none;
    }
  }
  @media only screen and (max-width: 320px) {
    width: 14rem !important;
  }
  @media only screen and (max-width: 375px) and (min-width: 321px) {
    width: 17.2rem !important;
  }
  @media only screen and (max-width: 375px) and (min-width: 321px) {
    width: 17.2rem !important;
  }
  @media only screen and (max-width: 425px) and (min-width: 376px) {
    width: 20.5rem !important;
  }
}

// overide
.nav-item1 a {
  border-bottom: none !important;
}
.nav-item2 {
  border-bottom: none !important;
}
.nav-item3 {
  @media only screen and (min-width: 993px) {
    border-bottom: 1px solid #dee2e6 !important;
  }
}

.roundedborder{
  @media only screen and (min-width: 993px) {
    border-radius: 0.25rem;
    border: 1px solid #dee2e6 !important;
  }
}

.fmb {
  font-family: AirbnbCerealApp !important;
}
.ob {
  opacity: 0.8 !important;
}
.placeholderb::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  font-size: 11px !important;
  //   align-items: center !important;
  // opacity: 0.5 !important;
  //   color: red !important;
  color: black !important;
}
.placeholdercontact::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  // font-size: 11px !important;
  //   align-items: center !important;
  // opacity: 0.5 !important;
  //   color: red !important;
  color: #5e5e5e !important;
  //font-family: AirbnbCerealApp;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: left;
  color: var(--black-four);
  opacity: 0.7;
}
.opacitymob {
  opacity: 0.5 !important;
}

.count_sticker {
  margin-left: 10px;
  font-size: 12px;
  // padding: 2px;
  border-radius: 50%;
  background-color: $dull-orange;
  color: white;
  min-width: 22px;
  height: 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-booking2 {
  .first-of-type {
    border-top: 1px solid #d1d0d0af !important;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
  }
  .accordion-button:not(.collapsed) {
    color: white !important;
    background-color: #3165ba !important;
    -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    font-weight: 500 !important;
    opacity: 2 !important;

    .count_sticker {
      background-color: white;
      color: black;
    }
  }

  .accordion-button::after {
    background-image: url("/images/indicator-arrow.png") !important;
    transform: rotate(0deg) !important;
    font-weight: 500 !important;
    opacity: 2 !important;
    // font-size: 9px;
  }
}
.button-booking-disabled {
  .accordion-button {
    opacity: 0.5 !important;
  }
  .accordion-button:not(.collapsed) {
    background-color: #fff;
    opacity: 0.5 !important;
    color: #212529;
  }
  .accordion-button::after {
    background-image: none !important;
  }
  .accordion-body {
    padding: 0 !important;
  }
}

.color-booking {
  color: #5e5e5e !important;
}
.color-payment {
  color: #5e5e5e !important;
}
.check-img {
  @media only screen and (max-width: 280px) {
    margin-top: -65px !important;
  }
  // position: absolute !important;
  // z-index: auto !important;
  // margin-top: -42px;
  // margin-left: -30px;
}
.credit-card {
  color: red;
}

.booking-form-scroll {
  ::-webkit-scrollbar {
    display: none !important;
  }
  overflow-y: scroll;
  height: 88vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
}
.booking-form-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.pkg-list-top {
  border: 1px solid #dee2e6;
  border-bottom: none !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.pkg-list {
  border: 1px solid #dee2e6;
  border-bottom: none !important;
}
.pkg-list-bottom {
  border: 1px solid #dee2e6 !important;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
