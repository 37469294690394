.listing-container {
  background-color: aqua;
}

.add-list-sticker {
  border-radius: 50px;
  width: 25px;
  height: 25px;
  font-size: 8px;
  padding: 2px 5px;
}

.add-list-sticker-iternary {
  border-radius: 50px;
  width: 1px;
  height: 3px;
  font-size: 5px;
  padding: 1px 4px 1px 4px;
  margin-top: -500px;
  position: relative;
}

.retreat-list .inner_nav {
  .active {
    color: #3165BA;
    // background-color: #3165BA;
    text-decoration: none !important;
    border: 0 !important;
  }

  .activeNew {
    color: #fff;
    background-color: #3165BA;
    text-decoration: none !important;
    border: 0 !important;
    padding: 6px;

  }

  .text-muted {
    color: #5e5e5e !important;
  }

  .text-muted1 {
    color: #3165BA !important;
  }

  .innerNavNew {
    border: 1px solid #3165BA;
    border-radius: 20px;
    padding: 6px;
    text-align: center;

  }

  @media only screen and (max-width: 767px) {
    .innerNavNew {
      border: 1px solid #3165BA;
      border-radius: 20px;
      padding: 8px 6px;
      text-align: center;

    }
  }

  .innerNavNew:hover {
    color: #fff !important;
    background-color: #3165BA;
    text-decoration: none !important;
    border: 0 !important;
    transition: 0.5s;

  }
}

.inner_nav .borderRight {
  @media only screen and (max-width: 767px) {
    border-right: 2px dashed #5e5e5e;
  }
}

.inner_nav .borderBottom {
  @media only screen and (max-width: 767px) {
    border-bottom: 2px dashed #5e5e5e;
    padding-bottom: 8px;
  }
}

.inner_nav p {
  font-size: 16px;
  margin-bottom: 0;

  @media only screen and (max-width: 767px) {
    font-size: 14px !important;
  }

  // @media only screen and (min-width: 363px) and (max-width: 485px) {
  //   font-size: 14px !important;
  // }

  // @media only screen and (min-width: 386px) and (max-width: 480px) {
  //   font-size: 14px !important;
  //   // padding-bottom: 5px;
  // }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 11.5px;
    padding: 3.5px !important;
  }

  @media only screen and (min-width: 991px) and (max-width: 1200px) {
    font-size: 14px;
  }
}

.unique-experience {
  @media only screen and (max-width: 480px) {
    font-size: 12px !important;
    margin-left: 5px !important;
  }

  @media only screen and (min-width: 480px) and (max-width: 767px) {
    font-size: 13px;
  }
}

.u {
  text-decoration: underline !important;
}

.retreat-list .search,
.homepage .search {
  position: relative;
  .search-btn {
    border-top-left-radius: none !important;
    border-bottom-left-radius: none !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    @media only screen and (max-width: 768px) {
      border-radius: 5px;
    }
  }

  .custom_search {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important;
  }

  .custom-date {
    border-left: 1px solid #c4c0c0b8 !important;
  }

  ::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px transparent;
    background: #3165ba1e;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3165ba41;
    border-radius: 5px;
  }
}

@media (max-width: 767px) {

  .retreat-list .search,
  .homepage .search {

    .search-btn,
    .custom_search,
    .custom-date {
      border-radius: 4px !important;
    }
  }
}