.listig_filter {
  color: #5e5e5e;
  .input-range {
    margin: auto auto auto auto !important;
  }

  
  .input-range__slider {
    background: #fff !important;
    border: 3px solid $dull-orange !important;
    width: 16px !important;
    height: 16px !important;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  }
  .input-range__track--active {
    background: $dull-orange !important;
  }
  // .input-range__track {
  //     // background: #ffddc6 !important;
  // }
  .input-range__label {
    display: none;
    // color: $black;
    // color:$dark !important;
    // display: none;
    // font-weight: bold;
    // font-family: "Muli" !important;
  }

  .input-range__label--value .input-range__label-container {
    // display: block;
    // background:$dull-orange;
    // color: white;
    // font-size: 12px;
    // padding: 1px 5px;
    // border-radius: 2px;

    // ---------------------------
    // margin-top: 37px;
    // position: absolute;
    // background:white;
    // // left: -20px;
    // left:-5px;
    // display: none;
  }

  .input-range__label--min .input-range__label-container,
  .input-range__label--max .input-range__label-container {
    //  display: none;
  }
}

.booking-range {
  .input-range {
    margin-left: 8px !important;
    margin-right: 8px !important;
    width: 8rem !important;
  }
  .input-range__slider {
    background: $dull-orange !important;
    border: 3px solid $dull-orange !important;
    width: 16px !important;
    height: 16px !important;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  }
  .input-range__track--active {
    background: $dull-orange !important;
  }
  // .input-range__track {
  //     // background: #ffddc6 !important;
  // }
  .input-range__label {
    display: none;
    // color: $black;
    // color:$dark !important;
    // display: none;
    // font-weight: bold;
    // font-family: "Muli" !important;
  }
}
