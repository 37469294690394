.accommodation-secondary-text {
  opacity: 0.8;
}

.accommodation-dropdown {
  .dropdown-menu {
    min-width: 7rem;
    // transform: translate(-115px, 7px) !important;
    // transform: translate(-21px, 1px) !important;
    border: none !important;
    border-radius: 4px;
    box-shadow: -2px 3px 8px rgba(82, 80, 80, 0.17);

    :hover {
      background-color: #3165ba;
      color: white;
    }

    // inset: 0 !important;
    // inset: 0px 0px auto auto;
  }
}

.numberCircle {
  position: absolute;
  bottom: 10px !important;
  right: 15px !important;
  width: 20px;
  line-height: 16px;
  padding-top: 2px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  border: solid 0.5px rgba(255, 255, 255, 0.6);
  background-color: #3165ba;
  color: #ffffff;
}

.accomodationCardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media only screen and (max-width: 991px) {
    height: 180px !important;
  }
}

.progress-backdrop {
  .modal-backdrop {
    background-color: transparent;
  }

  .modal-content {
    width: 70%;
    margin-left: 15%;
    margin-top: 50%;
  }

  .progress {
    height: 0.2rem;
    width: 90%;
  }
}

.progressBarMargin {
  @media only screen and (max-width: 767px) {
    margin-top: 20px !important;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: 12px !important;
  }
}
