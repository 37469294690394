.settings-background {
  background: white;
  border-radius: 6px;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.custom-file-input {
  color: #5e5e5e;
}
.custom-file-input::-webkit-file-upload-button {
  background-color: white;
  border-radius: 5px;
  border: 2px solid #ccc;
  padding: 5px 10px;
  margin-right: 1rem;
  color: #5e5e5e;
}

.margin-left-image {
  margin-left: 1rem;
}

.setting_input-group {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background: #ebf2fe !important;
  width: 3rem;
  justify-content: center;
}
.setting_input-group2 {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.preview-border {
  max-width: 35rem;
  //max-height:40px;
  background-color: #3165ba;
  color: white;
  //margin: 10px 27.5px 336px 26.5px;
  padding: 10px 10px;
  text-align: center;
}

.welcome-bar {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #e7e6e7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

.allpayemnt {
  .jexnEe {
    font-size: 11px;
    margin-top: 3px;
  }
}

.langauage-setting-footer {
  margin-top: 30rem;
}

.settings-input-color {
  input {
    width: 100%;
    padding-right: 93%;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      padding-right: 90%;
    }
  }
  label {
    margin-left: -92%;
    margin-top: 8px;
    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      margin-left: -89%;
    }
  }
}
