// @import "../utils/variables";
.form-switch,
.form-check-input:checked,
.form-check-input {
  box-shadow: none !important;
}

.basicdetails-radio {
  .form-check-input:checked {
    background-color: #008000 !important;
    border-color: #008000 !important;
    padding: 2px 2px 2px 2px;
    box-shadow: none !important;
  }

  .form-check-input {
    background-color: red;
    border-color: red;
    box-shadow: none !important;
  }
}

.roundedbordermyprofile {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}

// rouunder border bottom
.roundedbordermyprofilebottom {
  border-bottom-left-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important;
}

.placeholderMyprofile::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  font-size: 11px;
  //   color: red !important;
  // font-weight: bold;
  color: #5e5e5e !important;

  @media only screen and (min-width: 320px) {
    font-size: 10px !important;
  }
}

.placeholder-my-profile-payment-info::placeholder {
  font-size: 11px;
  color: #5e5e5e !important;
}

.placeholderWebsite::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  font-size: 11px;
  //   color: red !important;
  // font-weight: bold;
  color: black !important;

  @media only screen and (max-width: 320px) {
    font-size: 6.5px !important;
  }
}

.placeholderWebsite1::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  font-size: 10px;
  //   color: red !important;
  // font-weight: bold;
  columns: #5e5e5e !important;
  // @media only screen and (max-width: 410px) {
  //   font-size: 10px !important;
  // }
}

.placeholderMobile::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  font-size: 10px;
  left: -20px;
  //   color: red !important;
  // font-weight: bold;
  color: #5e5e5e !important;
  margin-left: -100px;

  @media only screen and (min-width: 320px) {
    font-size: 10px !important;
  }
}

.placeholderCommon::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  font-size: 10px;
  //   color: red !important;
  // font-weight: 550;
  font-style: normal;
  opacity: 0;
  color: #5e5e5e !important;

  @media only screen and (max-width: 320px) {
    font-size: 11px !important;
  }
}

.placeholderCommon2::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  font-size: 11px;
  //   color: red !important;
  // font-weight: 550;
  font-style: normal;
  opacity: 0;
  color: #5e5e5e !important;

  @media only screen and (max-width: 320px) {
    font-size: 11px !important;
  }
}

.placeholderContact::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  // font-size: 11px;
  //   color: red !important;
  // font-weight: 550;
  font-style: normal;
  font-size: 10.8px;
  opacity: 0;
  color: black !important;

  @media only screen and (max-width: 425px) {
    // margin-top: 15px;
    left: 8px;
  }

  @media only screen and (max-width: 375px) {
    font-size: 7px !important;
  }
}

.placeholderBusinessOwner::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  // font-size: 11px;
  //   color: red !important;
  // font-weight: 550;
  font-style: normal;
  font-size: 10.8px;
  opacity: 0;
  color: black !important;

  @media only screen and (max-width: 425px) {
    // margin-top: 15px;
    left: 8px;
  }
}

// Check-box notification

// Five

// Checkmark iternary

.backgrouncolormyprofile {
  background-color: rgba(220, 220, 220, 0.25) !important;
}

// Mobile design margins
.marginmyprofile {
  @media only screen and (max-width: 425px) {
    margin-top: 15px;
  }
}

.iphone5margin {
  @media only screen and (min-width: 320px) {
    margin-left: -10px;
  }
}

.placeholderpayment::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  // font-size: 10px;
  // left: -20px;
  //   color: red !important;
  // font-weight: bold;

  @media only screen and (min-width: 320px) {
    font-size: 10px !important;
  }
}

// My-Profile Frontend
.placeholdermyprofile-frontend::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  // font-size: 10px;
  // left: -20px;
  //   color: red !important;
  // font-weight: bold;
  color: black;
}

.myprofile-frontendselect {
  color: black !important;
}

.myprofile-frontendquill {
  @media only screen and (max-width: 425px) {
    height: 200px !important;
  }
}

.radio-help {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 14px;
    height: 14px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #E7E6E7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

.my-profile-text {
  color: #2b2b2b !important;
}

.my-profile-scrollabel {
  -webkit-scrollbar {
    // display: none !important;
  }

  overflow-y: auto;
  overflow-x: hidden;
  height: 22rem;
  // padding-right: 2rem;
  // -ms-overflow-style: none; /* for Internet Explorer, Edge */
  // scrollbar-width: none;
}

.my-profile-scrollabel::-webkit-scrollbar {
  // display: none; /* for Chrome, Safari, and Opera */
}

.school-myprofile_datepicker {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 5px;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 13px;
    width: 14rem;
    padding: 6px;
  }
}

.school-myprofile_datepicker2 {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 5px;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 11px;
    width: 26.2rem;
    padding: 6px;

    @media only screen and (max-width: 425px) {
      width: 16.6rem;
    }
  }
}

.notifiction-checkbox {
  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 15px;
    width: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    position: relative;
    margin-top: -1px;
    margin-right: 10px;
    //right:5px;
    top: 3px;
  }

  input[type="checkbox"]:checked {
    background: #3165ba;
    border-radius: 2px;
    border: 1px solid #3165ba;
  }

  input[type="checkbox"]:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;

    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input[type="checkbox"]:checked:after {
    display: block;
  }
}

// .basic-details-custom-file-input {

// }

.basic-file {
  input[type="file"] {
    display: none;
    margin: 10px;
  }

  input[type="file"] + label {
    display: block !important;
    //margin:20px;
    padding: 4px 12px;
    background-color: #3165ba;
    border: solid 1px #3165ba;
    border-radius: 4px;
    color: white;
  }

  input[type="file"]:active + label {
    background-image: none;
    background-color: #3165ba;
    color: #ffffff;
  }
}

.payment-account-button {
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: white;
  padding: 8px 12px;
  color: #ff0000;
}

.certificate-switch {
  .form-check-input {
    height: 0.8em !important;
    width: 1.4em !important;
  }
}

.school-profile-businessowner-calendar {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 5px;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 11px;
    width: 21.2rem;
    padding: 8px;

    @media only screen and (max-width: 425px) {
      width: 16.6rem;
    }
  }
}

.schoolquill {
  .ql-editor p {
    font-size: 11px;
    font-family: "Montserrat";
  }
}

.file-button {
  ::-webkit-file-upload-button {
    background: #3165ba;
    color: white;
    //padding: 1em;
    border-radius: 3px;
    border: none;
    font-size: 11px;
    padding: 8px;
  }

  input[type="file"] {
    font-size: 11px;
  }
}

.form-height {
  min-height: 20rem;
}

.school-profile-admin-footer {
  width: 597px !important;
  position: fixed !important;
  bottom: 0px !important;
  right: 0px !important;
}
