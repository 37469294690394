.instr-card {
  @media only screen and (min-width: 992px) {
    width: 25% !important;
  }
}

.tooltip-inner {
  font-size: 11px !important;
  padding-top: 2px !important;
}
