@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
  @font-face {
    font-family: "Montserrat";
    src: url("../../../public/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("../../../public/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;

}
@font-face {
  font-family: "Montserrat";
  src: url("../../../public/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;

}

// @font-face {
//   font-family: "NunitoSans";
//   src: url("../../../public/fonts/") format("truetype");
//   font-weight: normal;
//   font-style: italic;
//   font-display: swap;
// }
// @font-face {
//   font-family: "NunitoSans";
//   src: url("../../../public/fonts/NunitoSans-ExtraLight.ttf") format("truetype");
//   font-weight: 300;
//   font-style: lighter;
//   font-display: swap;
// }
