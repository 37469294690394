.about-header-text {
  font-size: 40px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;

  }
}

.header_about {
  font-size: 46px;

  @media only screen and (max-width: 480px) {
    font-size: 34px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 39px;
  }

  @media only screen and (min-width: 768px) and (max-width:1024px) {
    font-size: 45px;
  }
}

.header_about_paragraph {
  font-size: 22px;

  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 768px) and (max-width:1024px) {
    font-size: 20px;
  }
}

.about_title {
  font-size: 34px !important;

  @media only screen and (max-width: 480px) {
    font-size: 22px !important;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 28px !important;
  }

  @media only screen and (min-width: 768px) and (max-width:991px) {
    font-size: 25px !important;
  }

  @media only screen and (min-width: 992px) and (max-width:1024px) {
    font-size: 30px !important;
  }

  @media only screen and (min-width: 1024px) and (max-width:1200px) {
    font-size: 30px !important;
  }
}

.about-text {
  font-size: 17px;
  text-align: justify;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 991px) and (max-width: 1024px) {
    font-size: 15px;
  }

  @media only screen and (min-width: 1024px) and (max-width:1200px) {
    font-size: 15px;
  }
}