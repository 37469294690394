.placeholdertextarea-help::placeholder {
  //   color: red !important;
  //   opacity: 1; /* Firefox */
  //   font-size: 7px !important;
  //   align-items: center !important;
  //opacity: 0.5 !important;
  // font-size: 10px;
  // left: -20px;
  //   color: red !important;
  // font-weight: bold;
  font-size: 11px;
  opacity: 0.5 !important;
  //   color: red !important;
}
