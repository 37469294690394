@import "../utils/variables";

body {
  color: #2b2b2b !important;
}

.btn_container {
  position: absolute;
  right: 10px;
  top: 0px;
}

// .btn:hover {
//   border-color: #3165BA !important;
//   background: #fff !important;
//   color: #3165BA !important;
// }
.ScrollActiveMarginTop {
  margin-top: 100px;
}
.btnHover:hover {
  border-color: #3165ba !important;
  background: #fff !important;
  color: #3165ba !important;
  transition: 0.5s;
}

.btnHoverNew:hover {
  border-color: #fff !important;
  background: #3165ba !important;
  color: #fff !important;
  transition: 0.5s;
}

.btnHover1:hover {
  border-color: #e61919 !important;
  background: #fff !important;
  color: #e61919 !important;
  border: 1.5px solid #e61919 !important;
  border-radius: 5px;
}

input:focus {
  border-color: #3165ba !important;
  background: #fff !important;

  border-width: 2px !important;
}

input[type="number"]:focus {
  border-color: #3165ba !important;
  background: #fff !important;
  border-width: 2px !important;
}

input[type="password"]:focus {
  border-color: #3165ba !important;
  background: #fff !important;
  border-width: 2px !important;
}

.passwordFocus:focus {
  border-color: #3165ba !important;
  background: #fff !important;
  border-width: 2px !important;
}

textarea:focus {
  border-color: #3165ba !important;
  border-width: 2px !important;
  outline-color: #3165ba !important;
}

.form-control:focus {
  border-color: #3165ba !important;
  border-width: 2px !important;
}

.MuiInputBase-input-MuiOutlinedInput-input:focus {
  border-color: #3165ba !important;
  border-width: 2px !important;
}

.MuiInputBase-input-MuiOutlinedInput-input:hover {
  border-color: #3165ba !important;
  border-width: 2px !important;
}

// text--------------------------------------------
.form-control:focus {
  border-color: #3165ba !important;
  border-width: 2px !important;
}

.text-medium-blue {
  color: $medium-blue !important;
}

.text-dark {
  color: black;
}

.text-white {
  color: $white;
}

.text-about-title {
  color: #2b2b2b !important;
}

.text-light-navy {
  color: #3165ba !important;
}

.text-light-navy-two {
  color: $light-navy-two !important;
}

.text-title-black {
  color: $title-black !important;
}

.text-dull-oranage {
  color: $dull-orange !important;
}

.dull-oranage {
  color: $dull-orange !important;
}

.brownish-grey {
  color: $brownish-grey;
}

.text-brownish-grey {
  color: $brownish-grey !important;
}

// bg---------------------------------------------------
.bg-white-Four {
  background: $white-Four !important;
}

.bg-medium-blue {
  background-color: $medium-blue !important;
}

.bg-dull-oranage {
  background-color: $dull-orange !important;
}

.bg-info-popup {
  background-color: #5558 !important;
}

// -----------------------------------------------------------

// border radious
.br-3 {
  border-radius: 3px;
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-l-5 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.br-r-none {
  border-top-right-radius: none !important;
  border-bottom-right-radius: none !important;
}

.b-none {
  border: none !important;
}

.b-bottom-0 {
  border-bottom: 0 !important;
}

// box-shadow--------------------------------------------------------
.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: none !important;
}

.pointer {
  cursor: pointer;
}

.move {
  cursor: move;
}

.imageHeight {
  max-height: 64px;
}

.browserFiles::-webkit-file-upload-button {
  background-color: rgb(150, 29, 29);
  // color: red;
  padding: 1em;
  content: "Select some files";
}

.form-check-input:checked {
  background-color: #3165ba !important;
  border-color: #3165ba !important;
}

.addlist-radio {
  .form-check-input {
    box-shadow: none !important;
  }

  .form-check-input:checked {
    background-color: #3165ba !important;
    border-color: #3165ba !important;
    box-shadow: none !important;
  }
}

// .browseFile::before {
//   content: 'Select some files';
//   display: inline-block;
//   background: linear-gradient(top, #f9f9f9, #e3e3e3);
//   border: 1px solid #999;
//   border-radius: 3px;
//   padding: 5px 8px;
//   outline: none;
//   white-space: nowrap;
//   -webkit-user-select: none;
//   cursor: pointer;
//   text-shadow: 1px 1px #fff;
//   font-weight: 700;
//   font-size: 10pt;
//}
//-------------------------------buttons--------------------------------------
.btn-outline-orange {
  border: 1px solid $dull-orange !important;
  color: $dull-orange !important;
}

.btn-outline-red {
  border: 1px solid rgba(226, 13, 13, 0.837) !important;
  color: rgba(226, 13, 13, 0.837) !important;
}

.btn-outline-light-blue {
  border: 1px solid #6393e1ec !important;
  color: #6393e1ec !important;
}

.btn-outline-black {
  border: 1px solid black !important;
  color: black !important;
}

// hover-----------------------------------
.hover {
  .btn-outline-primary:hover {
    background-color: $medium-blue !important;
    box-shadow: none !important;
    color: white !important;
  }

  .btn-outline-red:hover {
    background-color: rgba(226, 13, 13, 0.837) !important;
    box-shadow: none !important;
    color: white !important;
  }

  .btn-outline-light-blue:hover {
    background-color: #6393e1ec !important;
    box-shadow: none !important;
    color: white !important;
  }
}

//active -----------------------------------------------------------------------
.btn-outline-primary-active {
  background-color: $medium-blue !important;
  box-shadow: none !important;
  color: white !important;
}

.btn-outline-red-active {
  background-color: rgba(226, 13, 13, 0.837) !important;
  box-shadow: none !important;
  color: white !important;
}

// -----------------switch---------------------------------------------------------
.custom-switch {
  .form-check-input:checked {
    background-color: #008000 !important;
    border-color: #008000;
    padding: 4px 4px 4px 4px;
  }

  .form-check-input {
    box-shadow: none !important;
    background-color: red;
    border-color: red;
    width: 20;
    cursor: pointer !important;
  }
}

.hide_arrow {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.css-1rhbuit-multiValue {
  background-color: $medium-blue !important;
  border-radius: 3px !important;
  color: white !important;

  .css-12jo7m5 {
    color: white !important;
  }
}

.text-wrap {
  inline-size: 100% !important;
  overflow-wrap: break-word !important;
}

.hide-text-overflow {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.innerhtml {
  margin-bottom: 1.5px;
  // inline-size: '100%';
  inline-size: 100%;
  // overflow-wrap: 'break-word';
  overflow-wrap: anywhere;

  p {
    margin: 0;
  }
}

.text-wrap2 {
  overflow-wrap: anywhere !important;
}

.listingCard {
  @media (min-width: 992px) {
    min-height: 200px;
    // max-height: 200px;
  }
}

.rotate {
  transform: rotate(180deg);
}

.listing_card_image {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (max-width: 767px) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px !important;
  }
}

.listing_card_details {
  border: 1px solid #ced4da;
  border-left: 0;

  @media (max-width: 767px) {
    border: 1px solid #ced4da;
    border-top: 0;
  }
}

.accomodation_card_image {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (max-width: 991px) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px !important;
  }
}

.accomodation_card_details {
  border: 1px solid #ced4da;
  border-left: 0;

  @media (max-width: 991px) {
    border: 1px solid #ced4da;
    border-top: 0;
  }
}

.booking_card_image {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  @media (max-width: 768px) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px !important;
  }
}

.booking_card_detailsMiddle {
  border: 1px solid #ced4da;
  border-left: 0;
  border-right: 0;

  @media (max-width: 991px) {
    border: 1px solid #ced4da;
    border-top: 0;
    border-bottom: 0px !important;
  }
}

.booking_card_detailsEnd {
  border: 1px solid #ced4da;
  border-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 991px) {
    border: 1px solid #ced4da;
    border-top: 0px !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

// -------------------------------------------
@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }

  ._nav_ {
    max-height: 77px !important;
    background: #fff;
  }
}

.datatable .rdt_Table {
  border: none !important;
}

.rdt_Table {
  border: 1px solid #dee2e6 !important;
}

a {
  color: #3165ba;
}

// .main-container{
//   margin: 60px auto;
//   // padding: auto 40%;
// }
.content-container {
  width: 80%;
  margin: auto;
}

.css-rvgcab-MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.WorldMap path {
  fill: #89abff;
  max-width: 250px !important;
}

.WorldMap svg {
  max-width: 20px !important;
}

.CircleChartCol {
  max-width: 48% !important;

  @media (max-width: 1200px) {
    max-width: 100% !important;
  }
}

.page404 {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 30px !important;
    font-weight: 700;
    color: #171725;

    @media only screen and (max-width: 480px) {
      font-size: 20px !important;
    }

    @media only screen and (min-width: 481px) and (max-width: 768px) {
      font-size: 26px !important;
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 26px !important;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: #5e5e5e;

    @media only screen and (max-width: 768px) {
      font-size: 20px !important;
    }

    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 22px !important;
    }
  }

  h5 {
    font-size: 20px;
    font-weight: 500;
    color: #171725;

    @media only screen and (max-width: 768px) {
      font-size: 18px !important;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;

    @media only screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }

  .ErrorImage {
    @media only screen and (min-width: 600px) and (max-width: 991px) {
      width: 70% !important;
    }
  }

  .ArrowImage {
    width: 60px;
    margin-top: -40px;
  }

  .ArrowImage img {
    right: -400px !important;
    position: relative;
  }
}

.shereYourProfile {
  @media only screen and (max-width: 767px) {
    min-width: 120px !important;
  }
}

// MUI Dropdown -------------->

.css-hrjh4n-MuiInputBase-root .MuiInputBase-input {
  border-radius: 5px;
  padding: 5px 26px 5px 12px !important;
  font-weight: 500;
  color: #5e5e5e;
}

.css-hrjh4n-MuiInputBase-root .MuiInputBase-input:focus {
  border-radius: 5px !important;
  border-color: #ddd !important;
  box-shadow: none !important;
}

.css-m3l1ig-MuiNativeSelect-select-MuiInputBase-input:focus {
  background-color: #fff !important;
}

// .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
//   font-size: 16px !important;
//   border-radius: 5px !important;
// }

.MuiOutlinedInput-root {
  padding: 3px !important;
  border-radius: 6px !important;
  max-height: 37px;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.ListingInstructors .css-dmmspl-MuiFormGroup-root {
  position: absolute !important;
  width: 20%;
}

.ListingInstructors .css-i4bv87-MuiSvgIcon-root {
  color: #fff !important;
  width: 0em !important;
}

.ListingInstructors .css-1h7anqn {
  display: flex;
  flex-flow: column wrap;
  width: 35% !important;
  position: absolute !important;
}

.ListingInstructors .css-vubbuv {
  width: 0em !important;
}

.RegistrationClam {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.userMessgaeClam {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.userMessgaeTitleClam {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.listingFooterButtons {
  background-color: #fff;
}

.anticon-dollar svg {
  width: 20px !important;
  height: 20px !important;
}

.voucherImg {
  min-height: 103px;

  @media only screen and (min-width: 426px) and (max-width: 462px) {
    min-height: 115px;
  }

  @media only screen and (min-width: 576px) and (max-width: 766px) {
    min-height: 135px;
  }

  @media only screen and (min-width: 767px) and (max-width: 992px) {
    min-height: 91.4px;
  }

  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    min-height: 82px;
    width: 300px !important;
  }

  @media only screen and (min-width: 1200px) {
    width: 380px !important;
  }
}

.voucherImg1 {
  display: flex;

  @media only screen and (max-width: 575px) {
    display: none;
  }
}

.voucherImg2 {
  display: none;

  @media only screen and (max-width: 575px) {
    display: flex;
  }
}

.frontDatePicker .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 0;
}

.frontDatePicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  max-height: 35px !important;
  border-radius: 3px !important;
  font-weight: 500 !important;
  font-size: 14px;
  color: #5e5e5e;
}

.frontDatePicker .css-i4bv87-MuiSvgIcon-root {
  font-size: 1.2rem;
}

.muiRadio .css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
  // font-weight: 500 !important;
}

.muiRadio .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0 9px 0 0 !important;
}

.muiRadio .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #3165ba !important;
}

.muiCheckRadio .css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
  // font-weight: 500 !important;
}

.muiCheckRadio .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0 9px 0 0 !important;
}

.muiCheckRadio .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px 9px 0 0 !important;
}

.muiCheckRadio .css-j204z7-MuiFormControlLabel-root {
  margin-left: 0px !important;
  margin-right: 16px;
}

.css-j204z7-MuiFormControlLabel-root {
  margin-left: 0px !important;
  margin-right: 16px;
}

.muiCheckRadio .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #3165ba !important;
}

.muiCheckRadio .css-i4bv87-MuiSvgIcon-root {
  font-size: 1.2rem;
  border-color: #bdbdbd;
}

.css-tfd1nu-MuiFormControlLabel-root {
  margin-left: 0 !important;
}

.certificatesHover:hover {
  cursor: pointer !important;
}

.textUpperCase {
  text-transform: capitalize;
}

// .placeholderText{
//   input::placeholder{
//     text-transform: capitalize;
//   }
// }
::placeholder {
  text-transform: capitalize;
  font-size: 12px !important;
}

.webUrl::placeholder {
  text-transform: lowercase !important;
  font-size: 12px !important;
}

/// New Calendar UI ////
.rmdp-container {
  width: 100%;
}

.rmdp-input {
  width: 100%;
  height: 40px !important;
}

.rmdp-week-day {
  color: #000 !important;
}

.rmdp-arrow {
  border-color: #000 !important;
}

.rmdp-arrow-container:hover {
  background-color: #3165ba !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border-color: #fff !important;
}

.datePicker > div > div > input {
  padding: 7px 12px !important;
}

.datePicker > div > div {
  font-size: 11px !important;
  padding: 0px !important;
  border-radius: 4px !important;
}

.makeStyles-frontDatePicker-1 .MuiInputBase-adornedEnd {
  height: 31.5px !important;
  font-size: 11px !important;
}

// .rmdp-container > div {
//   width: 100% !important;
// }
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .imageHeight {
    height: 100% !important;
    max-height: 102px;
  }

  .rmdp-container > div {
    width: 100% !important;
  }

  .rmdp-container > div .rmdp-calendar {
    width: 100% !important;
  }

  .rmdp-container > div .rmdp-calendar .rmdp-day-picker > div {
    width: 100% !important;
  }
}

.itineraryTimePicker > div > input {
  height: 100% !important;
  padding: 2.5px 8px !important;
  margin: 4px 0px !important;
  border-color: #dee2e6 !important;
}

.itineraryTimePicker > div {
  padding: 0px !important;
  border-radius: 4px !important;
  border-color: #dee2e6 !important;
}

.HostNewCarousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 90%;
  align-items: center;
}

.HostNewCarousel::-webkit-scrollbar {
  display: none;
}

.optionsList > span {
  bottom: -5.5px !important;
}

.MuiAutocomplete-option {
  font-size: 12px !important;
}

.pf-c-chart > svg {
  height: 250px !important;
  width: 350px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  padding-bottom: 10px !important;
}

@media screen and (max-width: 767px) {
  .pf-c-chart > svg {
    height: 200px !important;
    width: 280px !important;
    padding-bottom: 30px !important;
  }

  .messageReplyChart {
    max-height: 230px !important;
    max-width: 200px !important;
  }

  .messageReplyChartContainer {
    // max-height: 0px !important;
    height: auto !important;
  }

  .messageReplyChartLabel {
    font-size: 13px !important;
  }

  .messageReplyChartTimeLabel {
    font-size: 1.25rem !important;
  }

  .messageReplyChartLabel2 {
    bottom: 10px !important;
    left: 30px !important;
  }
}

.messageReplyChart {
  height: 100%;
  width: 280px;
}

.messageReplyChartContainer {
  max-height: 300px;
  height: 100%;
  width: 100%;
}

#chart1-ChartLabel-title tspan {
  font-size: 40px !important;
  font-weight: 600 !important;
  color: #313541 !important;
}

#chart1-ChartLegend-ChartLabel-0 tspan {
  font-size: 16px !important;
  margin-top: 10px !important;
}

.messageReplyChartLabel2 {
  bottom: 20px;
  left: 50px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .imageHeight {
    height: 100% !important;
    max-height: 72px !important;
  }

  .tabFontSize {
    font-size: 12px !important;
  }
}

// new Image Viewer
.styles-module_close__2I1sI {
  color: #000 !important;
  opacity: auto;
}

.styles-module_navigation__1pqAE {
  color: #000 !important;
  opacity: auto;
  font-size: 45px !important;
}

.react-simple-image-viewer__close {
  // top: 75px !important;
  right: 60px;
  z-index: 9 !important;
}

@media screen and (max-width: 667px) {
  .imageHeight {
    height: 100% !important;
    max-height: 85px !important;
  }
  .react-simple-image-viewer__close {
    top: 95px !important;
    right: 20px !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.MuiTabs-scroller {
  overflow-x: auto !important;
}
.MuiTabs-scroller::-webkit-scrollbar {
  display: none !important;
}
.bookingMarginTop {
  padding-top: 75px !important;
}
