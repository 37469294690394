.PhoneInput{
    .PhoneInputCountry{
        width: 56px;
        border: solid 0.5px #dcdcdc !important;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        height: 35px;

        .PhoneInputCountrySelect{
            font-size: 12px;

        }

        .PhoneInputCountryIcon{     
            box-shadow: none !important;
            background-color: transparent !important;
            padding: 0;
            width: 36px;
            height: 25px;
        }

        .PhoneInputCountryIcon img{
            border: solid 0.1px #dcdcdc65;

        }
        .PhoneInputCountryIcon svg{
            width: 30px;
        }
        
        // .PhoneInputCountryIconImg{
        //     &:before{
        //     content: "Select";
        //     font-size: 12px;
        //     color: #6c757d!important;
        //     padding: 0 0 0 5px ;
        //     }
        // }
        // .PhoneInputInternationalIconPhone,.PhoneInputInternationalIconGlobe{
            // display: none;
        // }
        

    }
    .PhoneInputInput{
        font-size: 12px;
        border-radius: 5px;
        border: solid 0.5px #dcdcdc;
        padding:8px;
        height: 35px;
    }
}


    .cardInputElement{
        font-size: 12px !important;
        border-radius: 5px !important;
        border: solid 0.5px #dcdcdc !important;
        padding:8px !important;    
}

