// @use "sass/styles/utils/variables" as *;

// .custom-modal,
// .modal-content {
//   // border-radius: $border-radius !important;
//   // margin: 0.5rem -2.5rem -0.5rem auto !important;
// }
// .modal-header {
//   // border-bottom: none;
// }
// .modal-body {
//   padding: 0rem 0 6rem 0;
// }
@media (max-width: 575.98px) {
  .modal-content {
    width: 100%;
  }
}
