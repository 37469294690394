.placeholder-login::placeholder {
  color: black !important;
}
.modal-login {
  .modal-content {
    width: 80% !important;
    // right: 30%;
    left: 10%;
  }
  @media only screen and (max-width: 540px) {
    .modal-content{
      width: 100% !important;
      left:0%
    }
  }
  //   margin-top: 10%;
}
.modal-close {
  .btn-close {
    border-radius: 0 !important;
    height: 0.1px !important;
    // background: "/images/close.png" !important;
    background-image: url("/images/close1.png") !important;
    // width: 5px !important;
    // height: 5px !important;
    background-size: 12px 12px;
    opacity: 0.7;
  }

  @media only screen and (max-width: 540px) {
    .btn-close{
      background-size: 15px 15px;
    }
  }

}
.modal-close1 {
  .btn-close {
    border-radius: 0 !important;
    // height: 0.1px !important;
    // background: "/images/close.png";
    // width: 5px !important;
    // height: 5px !important;
    // background-size: 24px 24px;
    margin-left: 50px;
    padding-left: -10px;
  }
}
