.admin-retreats-pagination,
.booking-pagination {
  nav {
    font-size: 11px;
    // margin-top: 3px;
    color: #313541;
    border-top: 0 !important;
    border: 1px solid #e3e7ed;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  //  nav,div:first-child{
  //     border:1px solid black !important
  //   }
} 
.admin_retreats {
  .css-tlfecz-indicatorContainer {
    align-self: baseline;
  }
}

.admin-table-pagination {
  nav {
    font-size: 11px;
    // margin-top: 3px;
    color: #313541;
    border-top: 0 !important;
    border: 1px solid #e3e7ed;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.admin-retreats-action__modal {
  .btn-primary {
    padding: 0.375rem 0.75rem !important;
  }
}
