// @import "../utils/variables";

.admin-booking {
  .css-tlfecz-IndicatorsContainer {
    align-self: baseline;
    overflow: hidden;
  }
}

.font-w-8 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 700 !important;
  color: #2b2b2b !important;
}

.active-booking {
  font-weight: 800 !important;
  color: #3165ba !important;
  border-bottom: 2px solid #3165ba;
}

.offcanvas-header {
  padding: "1px";
}

.nav-bar-border {
  border-bottom: solid 0.5px #d6d3d3;
}

.card-header-text-margin {
  margin-left: 27rem;
  cursor: pointer;
}

.f-color {
  color: #3165ba !important;
}

.f-email-color {
  color: #113c84 !important;
}

.f-color-darkblue {
  color: #113c84 !important;
}

.f-email-color {
  color: #113c84 !important;
  font-size: 13px;
  font-weight: 500;
}

.arrival-f {
  color: #5e5e5e;
  font-weight: 800 !important;
  font-size: 13px;
}

.fw-300 {
  font-weight: 500;
}

.custom-progress-bar {
  .progress-bar {
    background-color: #ccad2f;
  }
}

.uploder .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: #3165ba !important;
  transition: var(--bs-progress-bar-transition);
}

.booking-radio {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #e7e6e7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

.booking_modal {
  .modal-content {
    width: 75% !important;
    max-width: none !important;
    margin-top: 20%;
    margin-left: 15%;
  }
}

.booking_policy_modal {
  .modal-content {
    width: 80% !important;
    max-width: none !important;
    margin-top: 20%;
    margin-left: 15%;
  }
}

.bookings_sidebar {
  z-index: 1033 !important;
}

.brownish-grey {
  color: #5e5e5e;
}

.fs-13 {
  font-size: 13px !important;
}

.modal-footer-margin {
  margin-top: 8rem !important;
}

.datepicker-border {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 5px;
  }

  .react-date-picker__inputGroup {
    font-size: 11px;
    min-width: 29.4rem;
    padding: 6px;
  }
}

.booking_datepicker {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 5px;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 13px;
    width: 14rem;
    padding: 6px;
  }
}

.margin-left-take {
  margin-left: 13px;
}

.create-code-border {
  .css-m2yko3-control {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.booking-dropdown-button {
  background-color: white !important;

  border-color: #cdccd6 !important;
  padding: 0px;
  height: 33px !important;
  margin-top: 10px !important;
  color: #6c757d !important;

  .btn-primary:focus {
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 25%);
  }
}

.dropdown-toggle-booking {
  .dropdown-toggle::after {
    display: block;
  }

  .btn-primary {
    padding: none !important;
  }
}
