.black2b{
    color:#2b2b2b
}

.black262{
    color:#5e5e5e !important
}

.black333{
    color:#333333
}

.form-text-2b{
    .form-control{
       color:  #2b2b2b
    }

    .form-select{
        color:  #2b2b2b 
    }
}
