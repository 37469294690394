.payment-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}

.payment-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.payment-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.payment-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .payment-slider {
  background-color: #3165ba;
}

input:focus + .payment-slider {
  box-shadow: 0 0 1px #3165ba;
}

input:checked + .payment-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.payment-slider.round {
  border-radius: 36px;
  background-color: #e2e2e2;
}

.payment-slider.round:before {
  border-radius: 50%;
}
