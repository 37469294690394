.school-cursor {
  cursor: pointer;
}

.school-checkbox {
  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 15px;
    width: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    position: relative;
    right: 5px;
    top: 3px;
  }

  input[type="checkbox"]:checked {
    background: #3165ba;
    border-radius: 2px;
    border: 1px solid #3165ba;
  }
  input[type="checkbox"]:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;

    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  input[type="checkbox"]:checked:after {
    display: block;
  }
}

.table-margin {
  @media only screen and (max-width: 1044px) {
    margin-left: -35px;
  }
}
