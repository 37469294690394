.become-visionimg {
  position: absolute;
  right: 10%;
  opacity: 0.5;
}

.modal-becomepartner {
  .modal-content {
    width: 80% !important;
    // right: 30%;
    left: 10%;
  }

  @media only screen and (max-width: 540px) {
    .modal-content {
      width: 100% !important;
      left: 0%
    }
  }
}

.becomepartner-modal-close {
  @media only screen and (max-width: 400px) {
    .btn-close {
      margin: -1.6rem -0.5rem -0.5rem auto !important;


    }
  }
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem #fff !important;
  opacity: 1;
}

.placeholder-becomeparter::placeholder {
  color: black !important;
}

.placeholder-password::placeholder {
  font-size: 11px;
}

.placeholder-modalrequest::placeholder {
  font-size: 11px;
}