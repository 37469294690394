.fs-17 {
  font-size: 17px;
}

.admin-main,
.admin_retreats {
  background-color: #f4f7fc;
  .css-9gakcf-option {
    background-color: white;
    color: black;
  }
  .css-1n7v3ny-option {
    background-color: white;
  }

  // input[type="checkbox"] {
  //   cursor: pointer;
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   appearance: none;
  //   outline: 0;
  //   background: white;
  //   height: 15px;
  //   width: 15px;
  //   border: 1px solid #dcdcdc;
  //   border-radius: 2px;
  //   position: relative;
  //   right: 5px;
  //   top: 3px;
  // }

  // input[type="checkbox"]:checked {
  //   background: #3165BA;
  //   border-radius: 2px;
  //   border: 1px solid #3165BA;
  // }
  // input[type="checkbox"]:after {
  //   content: "";
  //   position: absolute;
  //   display: none;
  //   left: 5px;
  //   top: 2px;
  //   width: 4px;
  //   height: 8px;

  //   border: solid white;
  //   border-width: 0 1px 1px 0;
  //   -webkit-transform: rotate(45deg);
  //   -ms-transform: rotate(45deg);
  //   transform: rotate(45deg);
  // }
  // input[type="checkbox"]:checked:after {
  //   display: block;
  // }
}

.accordion-button-padding {
  button {
    padding: 2px 2px;
    position: inherit;
  }
}
.menu {
  margin-left: 1px;
  height: 3.4rem;
}

.menu-button {
  border: none;
  padding: 1rem;
  background-color: white;
}

.nav-link {
  padding-top: 2px;
}
.nav-pos {
  nav {
    position: inherit;
    background-color: white;
  }
}

.nav-top {
  width: 13rem;
  position: inherit;
}

.nav-top-toggle {
  width: 3.1rem;
}

.nav-bar-close {
  background: white;
  width: 13rem;
}

.nav-bar-open {
  background: white;
  width: 3.1rem;
}

.admin-select-checkbox {
  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 15px;
    width: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    position: relative;
    right: 5px;
    //top: 3px;
  }

  input[type="checkbox"]:checked {
    background: #3165ba;
    border-radius: 2px;
    border: 1px solid #3165ba;
  }
  input[type="checkbox"]:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;

    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  input[type="checkbox"]:checked:after {
    display: block;
  }
}
