.button-booking,
.button-experience {
  background-size: 10px 10px !important;

  // .accordion-button {
  //   font-weight: 500;
  // }
  //   .accordion-button:not(.collapsed) {
  //     // color: white !important;
  //     // background-color: #3165BA !important;
  //     // -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  //     // box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  //     // font-weight: 500;
  //     background-image: url("http://www.w3.org…0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  //   }
  .accordion-button::after {
    background-image: url("/images/dropdown.svg") !important;
    background-size: 1rem;
    // transform: rotate(0deg) !important;
  }

  .accordion-button:not(.collapsed)::after {
    margin-right: 3px !important;
  }

  .accordion-button p {
    padding-right: 5px;
  }

  //   .accordion-button::after {
  //     background-image: url("/images/indicator-arrow.png") !important;
  //     transform: rotate(0deg) !important;
  //   }
  .accordion-item {
    border: 0.5px solid #ddd;
  }
}

.online-retreat-images {
  min-height: 280px;
  border-radius: "6px";
}

@media only screen and (max-width: 768px) and (min-width: 376px) {
  .online-retreat-images {
    min-height: 650px;
  }
}