// #_add_listing_flow_ .Toastify__toast-container--top-center{
//     width: 45% !important;
//     margin-top: -10px;
// }

.Toastify__toast-container--top-center {
  @media only screen and (min-width: 769px) {
  width: 75% !important;
  }
  // width: auto !important;  
  margin-top: -10px;
  
}

.Toastify__toast-body {
  font-size: 14px;
  line-height: 15px;
  padding: 0px;
  width: 100%;
  font-weight: 400;
} 
.Toastify__toast{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px !important;
  padding-right: 15px !important;
}

.Toastify__toast-icon{
  display: none !important;
}

// -----------------------------------------------------------------------------------------
.Toastify__toast--success {
 border: 1px solid #289058 !important;
 background: #dcf8e7 !important;
 color:#289058 !important;
}

.Toastify__toast--success::before {
    background-image: url("/images/success.png");
    background-size: 19px 18px;
    display: inline-block;
    background-repeat: no-repeat;
    width: 23px; 
    height: 20px;
    content: "";
}
// -----------------------------------------------------------------------------------------------
.Toastify__toast--error {
  border: 1px solid #bf5555 !important;
  background: #ffe4e4 !important;
  color:#bf5555 !important;
 }
 .Toastify__toast--error::before {
  background-image: url("/images/alert.png");
  background-size: 19px 18px;
  display: inline-block;
  background-repeat: no-repeat;
  width: 23px; 
  height: 20px;
  content: "";
}
// ------------------------------------------------------------------------------------------------
.Toastify__toast--warning {
  border: 1px solid #cfb35f !important;
  background: #fff7df !important;
  color:#cfb35f !important;
}
.Toastify__toast--warning::before {
  background-image: url("/images/warning.png");
  background-size: 19px 18px;
  display: inline-block;
  background-repeat: no-repeat;
  width: 23px; 
  height: 20px;
  content: "";
}
// ------------------------------------------------------------------------------------------------------
.Toastify__toast--info{
  border: 1px solid #47a8f5 !important;
  background: #deeffd !important;
  color:#47a8f5 !important;
}
.Toastify__toast--info::before {
  background-image: url("/images/information.png");
  background-size: 19px 18px;
  display: inline-block;
  background-repeat: no-repeat;
  width: 23px; 
  height: 20px;
  content: "";
}



