#main-nav {
  background: #fff;
  // top: 38px;
}

#school-nav {
  background: #fff;
}

.navbar-nav {
  .nav-link {
    font-weight: 500 !important;
  }
}

.navbar-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-toggler {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.schoolnav_text-side {
  font-size: 15px !important;
  color: #222222 !important;
  padding: 6px !important;
  // font-weight: 900 !important;
}

.schoolnav_text {
  font-size: 0.9rem !important;
  color: #222222 !important;
  font-weight: 600;
}

.schoolnavbar__user-dropdown {
  .dropdown-menu {
    // width: 100% !important;
    border: none !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    // left: -100px !important;
    right: 0;
  }

  .dropdown-toggle {
    font-size: 12.5px !important;
    color: $dark  !important;
    font-weight: 500 !important;
  }

  a[aria-expanded="true"] {
    color: $medium-blue  !important;
  }

  .schoolnavbar__dropdown-userDetails {
    // max-width: 210px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background-color: #3165ba;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    img {
      height: 57px;
    }

    p {
      font-size: 0.9rem;
      margin-bottom: 0;
    }

    .dropdown-menu[data-bs-popper] {
      left: -100px !important;
      margin-top: 1.125rem !important;
    }
  }

  // .schoolnav_text {
  //   font-size: 1rem;
  //   color: $dark;
  //   font-weight: 500;
  // }

  margin: 0 !important;

  a {
    font-size: 0.9rem !important;
    margin-right: 0 !important;
    font-weight: 500;
  }

  button {
    background-color: #fff !important;
    padding: 0 !important;
    border: none;
    outline: none !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: $medium-blue  !important;
    color: $white  !important;

    span {
      color: $white  !important;
      font-weight: 500;
    }

    .navbardesktop_list-icon {
      filter: brightness(0) invert(1);
    }
  }

  // .navbardesktop_list-item {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  //   width: 100%;
  //   color: #5a5a5a;
  //   font-weight: 500;
  //   padding: 4px 3px;

  //   span {
  //     font-size: 0.8rem;
  //   }

  //   .navbardesktop_list-icon {
  //     margin-right: 14px;
  //   }
  // }
}

.schoolMessage-respond_dropDown {

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: $medium-blue  !important;
    color: $white  !important;
  }
}

.schoolnavbar__link-dropdown {
  .dropdown-menu {
    border: none !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;

    a {
      padding: 0.3rem 0.8rem !important;
    }
  }

  .dropdown-toggle {
    font-size: 0.9rem !important;
    color: #222222 !important;
    font-weight: 500 !important;
  }

  a[aria-expanded="true"] {
    color: $medium-blue  !important;
  }

  a {
    font-size: 0.8rem !important;
    margin-right: 0 !important;
    font-weight: 500 !important;
  }

  button {
    background-color: #fff !important;
    padding: 0 !important;
    border: none;
    outline: none !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: $medium-blue  !important;
    color: $white  !important;

    span {
      color: $white  !important;
      font-weight: 500;
    }

    .navbardesktop_list-icon {
      filter: brightness(0) invert(1);
    }
  }

  .dropdown-menu[data-bs-popper] {
    left: 2px !important;
    margin-top: -0.7rem !important;
  }

  // .navbardesktop_list-item {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  //   width: 100%;
  //   color: #5a5a5a;
  //   font-weight: 500 !important;
  //   padding: 4px 3px;

  //   span {
  //     font-size: 0.8rem;
  //   }

  //   .navbardesktop_list-icon {
  //     margin-right: 14px;
  //   }
  // }
}

.navbar__user-dropdown {
  margin: 0 !important;

  a {
    margin-right: 0 !important;
  }

  button {
    background-color: #fff !important;
    padding: 0 !important;
    border: none;
    outline: none !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    width: 240px !important;
    border: none !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 4px;
    margin-top: 3.1rem !important;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: $medium-blue  !important;

    span {
      color: $white  !important;
    }

    .navbardesktop_list-icon {
      filter: brightness(0) invert(1);
    }
  }

  .dropdown-menu[data-bs-popper] {
    // top: 5px !important;
    right: -6px !important;
  }

  .navbardesktop_list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    color: #5a5a5a;
    font-weight: 500;
    padding: 4px 3px;

    span {
      font-size: 1rem;
    }

    .navbardesktop_list-icon {
      margin-right: 14px;
    }
  }
}

.navbar_list-item {
  // border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 0px 12px 0px !important;
  font-weight: 500;
  font-size: 16px;
  color: #2b2b2b !important;

  .navbar_list-icon {
    color: #595959;
    width: 15px !important;
    height: auto !important;
  }

  // .navbar_list-icon:hover,
  // .navbar_list-icon:focus {
  //   color: #ffff;
  // }
  span {
    margin-left: 1.5rem;
  }

  &:hover {
    .navbar_list-icon {
      filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(202deg) brightness(108%) contrast(101%);
      color: white;
    }

    color: #3165ba !important;
  }
}

.profileNav-toggle {
  border: none !important;
}

.headername-nav {
  font-size: 1.2rem;
  margin-left: 2rem;
  padding-top: 0.6rem;
}

.headername-mobile-nav {
  display: none;
}

._notification_ {
  width: 23rem !important;
  left: -20rem !important;
  // margin-top: 0.4rem !important;
  padding: 0;
}

._notification_ .dropdown-item {
  background-color: transparent !important;
  padding: 0 !important;
  max-width: 24rem !important;
}

._notification_icon_ {
  position: relative;
  display: inline-block;

  .notification-badge {
    position: absolute;
    top: -2px;
    right: -3px;
    padding: 2px;
    border-radius: 50%;
    background-color: $dull-orange;
    color: white;
    min-width: 18px;
    height: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.link {
  color: #3165ba;
  cursor: pointer;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #3165ba;
}

@media screen and (max-width: 400px) {
  .schoolnav_text-midia {
    // margin-top: -20px !important;
    padding: 0 !important;
  }

  .schoolnavbar__link-dropdown {
    padding: 0 !important;

    .dropdown-menu[data-bs-popper] {
      margin-top: 0px !important;
      padding: 0px !important;
    }

    .dropdown-menu {
      border: none !important;
      box-shadow: none !important;

      a {
        padding: 0px 0px !important;
      }
    }

    .dropdown-toggle {
      font-size: 15px !important;
    }

    a[aria-expanded="true"] {
      color: $medium-blue  !important;
    }

    a {
      font-size: 14px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .headername-nav {
    display: none;
  }

  .headername-mobile-nav {
    display: block;
    font-size: 1.2rem;
    padding-top: 0.6rem;
  }
}

@media screen and (max-width: 1020px) {
  .schoolnav_text-midia {
    margin-top: 0px;
  }

  .notification-badge {
    top: -2px !important;
    right: -3px !important;
  }

  .schoolnav_text {
    font-size: 15px !important;
  }

  .schoolnavbar__link-dropdown {
    .dropdown-menu[data-bs-popper] {
      margin-left: 25px !important;
      margin-top: 0px !important;
      padding-top: 0px;
    }

    .dropdown-menu {
      border: none !important;
      box-shadow: none !important;

      a {
        padding: 5px 0px !important;
      }
    }

    a[aria-expanded="true"] {
      color: $medium-blue  !important;
    }

    a {
      font-size: 14px !important;
    }
  }
}

@media screen and (min-width: 990px) and (max-width: 1199px) {
  .imagebell {
    width: 32px !important;
    height: 32px !important;
    margin-right: 6px !important;
  }

  .dropdown-toggle {
    font-size: 5px !important;
  }

  .schoolnav_text-side {
    font-size: 15px !important;
    color: #222222 !important;
    padding: 6px !important;
    // font-weight: 900 !important;
  }

  .schoolnav_text {
    font-size: 0.8rem !important;
    color: #222222 !important;
    font-weight: 600 !important;
    padding: 0 !important;
  }

  .schoolnav_text-midia {
    margin-top: 8px !important;
  }

  .schoolnavbar__link-dropdown {
    .dropdown-menu[data-bs-popper] {
      left: 2px !important;
      margin-top: -0.2rem !important;
    }

    .dropdown-menu {
      border: none !important;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      border-radius: 4px;

      a {
        padding: 0.3rem 0.8rem !important;
      }
    }

    .dropdown-toggle {
      font-size: 0.8rem !important;
      padding: 0 !important;
      margin: 0 !important;
    }

    a[aria-expanded="true"] {
      color: $medium-blue  !important;
    }

    a {
      font-size: 10px !important;
    }
  }
}

.imagebell {
  width: 32px !important;
  height: 32px !important;
  margin-right: 0px !important;
}

//school containers overide
// .school__container {
//   // @media (min-width: 1400px)
//   // .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
//   //     max-width: 1320px;
//   // }
// }

/* Banner*/
.banner {
  width: 100%;
  top: 0;
  z-index: 111;
  text-align: center;

  .banner__content {
    padding-right: 8px;
  }
}

._addlist-mobile-nav_ {
  position: absolute;
  margin-left: -1px !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .schoolnavbar__link-dropdown {
    .dropdown-toggle {
      // font-size: 15px !important;
      // color: #222222 !important;
      // padding: 0.30px !important;
      // font-weight: 600 !important;
    }
  }
}

@media screen and (max-width: 576px) {
  ._notification_ {
    width: 20rem !important;
    left: -50vw !important;
    // margin-top: 0.4rem !important;
    padding: 0;
  }
}