.image-container {
  div:first-child {
    top: 50px !important;
    z-index: 1000 !important;
    background-color: #fff !important;
    color: #000 !important;
  }

  div:second-child {
    top: 50px !important;
    z-index: 1000 !important;
    background-color: #fff !important;
    color: red !important;
  }

  img {
    // width: 100% !important;
    // object-fit: contain !important;

    height: 75% !important;
    user-select: none !important;
  }

  @media only screen and (max-width: 993px) {
    img {
      object-fit: contain !important;
      height: auto !important;
    }
  }

  svg {
    fill: #333 !important;
  }
}

.gallery-lightbox__container {
  div:first-child {
    z-index: 2000 !important;
    background-color: #fff !important;
    color: #000 !important;
  }

  img {
    // width: 100% !important;
    // object-fit: contain !important;

    height: 75% !important;
  }

  @media only screen and (max-width: 993px) {
    img {
      object-fit: contain !important;
      height: auto !important;
    }
  }

  svg {
    fill: #333 !important;
  }
}

.retreat-hero {
  margin-bottom: 2rem;

  img,
  video {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  .hero--grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 12vw);
    grid-auto-flow: dense;
    grid-gap: 10px;
  }

  .hero--grid > *:first-child {
    grid-column: 1 / span 3;
    grid-row: 1 / span 2;
  }
}

.retreat-video__container {
}

.fixed_button {
  z-index: 999;

  bottom: 0;
  // right: 19.1% !important;
  // top: 50%;
  // width: 90%;
  @media only screen and (max-width: 767px) {
    // width: 100% !important;
    bottom: 0%;
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    width: 18.6rem !important;
    bottom: 1%;
  }

  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    width: 16.4rem !important;
    bottom: 1%;
  }

  @media only screen and (min-width: 1200px) {
    width: 21.4rem !important;
    bottom: 1%;
  }
}
.fixed_buttonmobile {
  z-index: 999;

  bottom: 0;
  // right: 19.1% !important;
  // top: 50%;
  // width: 90%;

  @media only screen and (max-width: 425px) {
    width: -webkit-fill-available;
    max-width: 95% !important;
    bottom: 0%;
    margin-left: -3px;
  }
  @media only screen and (min-width: 425px) and (max-width: 575px) {
    width: -webkit-fill-available;
    max-width: 95% !important;
    bottom: 0%;
    margin-left: -2px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: -webkit-fill-available;
    max-width: 32.5rem !important;
    bottom: 0%;
    margin-left: -2px;
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    width: 18.6rem !important;
    bottom: 1%;
  }

  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    width: 16.4rem !important;
    bottom: 1%;
  }

  @media only screen and (min-width: 1200px) {
    width: 21.4rem !important;
    bottom: 1%;
  }
}
.inquiry-modal-button {
  position: fixed;
  width: 100%;
  bottom: 0;
  margin: 0;
  right: 0;
  background: #fff;
  z-index: 999;
  padding: 10px;
  box-shadow: 1px -1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .inquiry-modal-button {
    width: 230px;
  }
}

@media (min-width: 1024px) {
  .inquiry-modal-button {
    width: 300px;
  }
}

.inquiry-modal-button:focus {
  outline: 0;
}

.inquiry-modal-button.in-view {
  position: static;
  border: 0;
  padding: 0;
  box-shadow: none;
}

.retreat-header button {
  padding-left: 0 !important;
}

.retreat-text-primary {
  font-size: 11px;
  font-weight: 400;

  @media only screen and (max-width: 540px) {
    font-size: 12px !important;
  }
}

.retreat-text-header {
  @media only screen and (max-width: 540px) {
    font-size: 1.35em !important;
  }
}

.retreat-text {
  font-size: 12.5px !important;
  font-weight: 400;

  @media only screen and (max-width: 540px) {
    font-size: 0.87rem !important;
    line-height: 30px;
  }
}

.retreat-secondary-header {
  font-size: 16px;
  font-weight: 600;

  @media only screen and (max-width: 540px) {
    font-size: 1.1rem;
  }
}

.send_Enquiry {
  position: fixed;

  @media only screen and (max-width: 767px) {
    position: relative !important;
  }
}

.food-drink-text,
.review-text,
.review-fs-10 {
  font-size: 10px;

  @media only screen and (max-width: 540px) {
    font-size: 13px;
  }
}

.review-border {
  @media only screen and (min-width: 992px) {
    border-left: 1px solid #dee2e6 !important;
  }
}

.instructor-margin {
  @media only screen and (min-width: 541px) {
    margin-top: -1.2rem !important;
  }
}

.retreat-fs-14 {
  font-size: 14px;

  @media only screen and (max-width: 540px) {
    font-size: 16px;
  }
}

.retreat-fs-13 {
  font-size: 13px;

  @media only screen and (max-width: 540px) {
    font-size: 15px;
  }
}

.mobile-checkbox {
  input[type="checkbox"] {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.img-carousel-caption {
  .carousel-caption {
    position: absolute;
    top: 0;
    left: 85%;
    width: 10%;
    text-align: center;
    //z-index: 999999999;
    padding-top: 5px;
    color: #fff;
  }
}

.disable-icon {
  .carousel-control-prev-icon {
    background-image: none !important;
  }

  .carousel-control-next-icon {
    background-image: none !important;
  }
}

.bottom-carousel {
  .carousel-caption {
    position: absolute;
    top: 79%;
    right: 5%;
    bottom: 2rem;
    left: 0;
    padding-top: 1.25rem;
    padding-bottom: 2.25rem;
    color: #fff;
    text-align: left;
  }
}
.top-carousel {
  // .carousel-caption {
  position: absolute;
  top: 0%;

  // bottom: 2rem;
  left: 0;
  padding: 10px 10px;
  color: #fff;
  top: 0;

  // border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
  // left: 16px;
  background: #84cc16;
}

.retreatFAQ {
  button {
    background: #f9f6fb !important;
    padding: 0.6rem 0.8rem;

    p {
      color: #333 !important;
      font-weight: normal;
      font-size: 11px !important;
    }
  }

  .accordion-button::after {
    background-image: url("/images/plus-icon.png");
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("/images/minus-icon.png");
  }
}

.accomodation-card {
  .accomodation-img {
    height: 70px;
    width: 5.8rem;
  }

  .accomodation-img__last {
    background: rgba(0, 0, 0, 0.5) !important;
    filter: brightness(0.5);
  }
}

// .retreat-gallery{
//   position: fixed;
//   bottom: 0;
//   z-index: 1045;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-flex-direction: column;
//   flex-direction: column;
//   max-width: 100%;
//   visibility: hidden;
//   background-color: #fff;
//   background-clip: padding-box;
//   outline: 0;
//   -webkit-transition: -webkit-transform .3s ease-in-out;
//   transition: -webkit-transform .3s ease-in-out;
//   transition: transform .3s ease-in-out;
//   transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
// }

// .retreat-gallery-bottom {
//   right: 0;
//     left: 0;
//     height: 90vh;
//     max-height: 100%;
//     border-top: 1px solid rgba(0,0,0,.2);
//     -webkit-transform: translateY(100%);
//     transform: translateY(100%);
// }

.retreat-gallery__media {
  max-width: 100%;
  height: 100%;
}

.offcanvas-bottom {
  height: 100vh !important;
}

.overview-paragraph {
  p {
    margin-bottom: 2px;
  }
}

.reach-accordion-button {
  button {
    width: 100%;
    border: none;
    display: flex;
    //border-bottom: solid 0.5px #dcdcdc;
    background-color: white;
    padding-top: 15px;
    padding-bottom: 12px;
    //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    background-size: 1.5rem;
    background-repeat: no-repeat;
    margin-left: auto;
    justify-content: "space-between";
    content: "";
    // transform: rotate(0deg) !important;
    align-items: flex-start;
  }
}

.accordion-display-none {
  display: none;
}

.retreat-image-container {
  position: relative;
  text-align: center;
  color: white;
}

.image-bottom-left {
  position: absolute;
  bottom: 1px;
  left: 16px;
}
.image-top-left {
  position: absolute;
  top: 0;
  padding: 10px 10px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 10px;
  align-items: center;
  // left: 16px;
  background: #84cc16;
}

.main-ct {
  width: 100px;
  height: 500px;
  border: 1px solid #000;
  position: relative;
  top: 50px;
}

.fixed-ct {
  position: sticky;
  width: 100px;
  height: 20px;
  background: red;
  top: 10px;
}

.like-body {
  width: 100%;
  height: 1300px;
  top: 50px;
}
