// import the functions & variables
// @use "sass/styles/utils/variables" as *;
@import "sass/styles/utils/variables";
@import "sass/styles/utils/mixins";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/_variables.scss";
// @import "node_modules/bootstrap/scss/maps.scss";
@import "node_modules/bootstrap/scss/mixins";
.w-85 {
  width: 85%;
}
// custom theme colours
$min-contrast-ratio: 1;
$custom-theme-colors: (
  "dark": #2222,
  "altlight": #f2ebfa,
  "altdark": #522192,
);

// merge the maps to add new theme colors
// $theme-colors: map-merge($custom-theme-colors, $theme-colors);
// @import "node_modules/bootstrap/scss/_utilities.scss";

// $utilities: map-merge(
//   $utilities,
//   (
//     "cursor": (
//       property: cursor,
//       values: auto default pointer wait text move help not-allowed,
//     ),
//   )
// );
// $utilities: (
//   "opacity": (
//     property: opacity,
//     print: true,
//     values: (
//       0: 0,
//       25: .25,
//       50: .5,
//       75: .75,
//       100: 1,
//     )
//   )
// );

// use bootstrap

// @import "node_modules/bootstrap/scss/bootstrap";
