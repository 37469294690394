.limedia {
  li {
    list-style-type: none;
    position: relative; /* It's needed for setting position to absolute in the next rule. */
    margin-left: -22px;
  }

  li::before {
    content: "■";
    position: absolute;
    left: -2.1em; /* Adjust this value so that it appears where you want. */
    font-size: 4px; /* Adjust this value so that it appears what size you want. */
    top: 7px;
  }
}

.input-tags {
  width: 100%;
  padding: 15px;
  display: block;
  margin: 0 auto;
}

.label-info {
  background-color: #5bc0de;
  padding: 3px;
}

.media-edit {
  .rti--container {
    font-size: 10px !important;
  }

  .go1186250972 {
    background-color: #3165ba;
    color: white;
    padding: 2px;
    padding-left: 4px;
  }
  .go1186250972 button {
    color: white;
  }
}

.tag-edit {
  .rti--container {
    font-size: 11px !important;
  }

  .go1186250972 {
    background-color: rgba(168, 168, 168, 0.4);
    color: #222222;
    padding: 4px;
    padding-left: 6px;
  }
  .go1186250972 button {
    color: 222222;
    margin: "none";
  }
}
