@import "../utils/variables";

.listig_filter,
.message_filter,
._clean_and_safe_ {
  /* The container */
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 10px auto 10px auto;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkbox-container .checkmark {
    position: absolute;
    // margin-top: 3.5px;
    left: 0;
    height: 16px;
    width: 17px;
    border: 0.1em solid #dcdcdc;
    // background-color: ;
    border-radius: 4px;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: $medium-blue;
    border-color: $medium-blue;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  //

  /* Create a custom checkbox */
  .checkbox-container .checkmark1 {
    position: absolute;
    // margin-top: 3.5px;
    left: 0;
    height: 16px;
    width: 17px;
    border: 0.1em solid #dcdcdc;
    // background-color: ;
    border-radius: 50% !important;
  }
}

// --------------------------------rounded-pill-checkbox--------------------------------------------------------------------

/* The container */
.rounded-pill-checkbox {
  margin: 7px 15px 7px 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.rounded-pill-checkboxgroup input {
  position: absolute;
  opacity: 0 !important;
  cursor: pointer;
  height: 0 !important;
  width: 0 !important;
}

.rounded-pill-checkboxgroup input ~ .checkmark {
  margin: auto 10px 8px auto;
  display: flex;
  border: 1px solid #a3a2a26c;
  color: #5e5e5e;
  align-items: baseline;
  border-radius: 60px;
}

.rounded-pill-checkboxgroup input ~ .checkmark::before {
  content: "+";
  color: #3165ba;
  margin: auto 3px auto 15px;
}

.rounded-pill-checkboxgroup input:checked ~ .checkmark::before {
  content: "✓";
  color: white;
  margin: auto 4px auto 15px;
  font-size: 11px;
}

/* When the checkbox is checked, add a blue background */
.rounded-pill-checkboxgroup input:checked ~ .checkmark {
  color: white;
  background-color: #3165ba;
  border: 1px solid #3165ba;
}

.rounded-Instructor-checkboxgroup input {
  position: absolute;
  opacity: 0 !important;
  cursor: pointer;
  height: 0 !important;
  width: 0 !important;
}

.rounded-Instructor-checkboxgroup input ~ .checkmark {
  margin: auto 10px 8px auto;
  display: flex;
  border: 1px solid #a3a2a26c;
  color: #5e5e5e;
  align-items: baseline;
  border-radius: 6px;
}

.rounded-Instructor-checkboxgroup input ~ .checkmark::before {
  content: "+";

  color: #3165ba;
  margin: auto 3px auto 15px;
}

.rounded-Instructor-checkboxgroup input:checked ~ .checkmark::before {
  content: "✓";
  color: white;
  margin: auto 4px auto 15px;
  font-size: 11px;
}

/* When the checkbox is checked, add a blue background */
.rounded-Instructor-checkboxgroup input:checked ~ .checkmark {
  color: white;
  background-color: #3165ba;
  border: 1px solid #3165ba;
}

// -----------------------------------------------------------------------------------
