.inputdateRangePicker-dropdown {
  border-radius: 5px;
  border: 1px solid #dee2e6;
  background-color: white;
  position: relative;
}

.inputdateRangePicker-header {
  padding: 7px;
  font-size: 12px !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputdateRangePicker-body {
  border-radius: 5px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  padding: 5px;
  z-index: 10;
  position: absolute;
  display: none;
  background: white;
  margin-top: 1%;
  overflow: auto;
}

.customrangecalendar {
  margin: 0 auto;
  .DateRangePicker__CalendarSelection--single {
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    left: 0px;
    right: 0px;
  }
  .DateRangePicker__CalendarSelection--start {
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
  }
  .DateRangePicker__CalendarSelection--end {
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
  }
  .DateRangePicker__MonthHeader {
    color: #222222 !important;
    font-weight: 600 !important;
  }
  .DateRangePicker__Date--weekend {
    background-color: #fff !important;
  }
  .DateRangePicker__Weekdays {
    background-color: #dcdcdc8c !important;
  }
  .DateRangePicker__WeekdayHeading abbr[title] {
    font-weight: 600 !important;
    color: #0202029e !important;
  }
  .DateRangePicker__CalendarSelection {
    // background-color: #3165BA;
    background-color: #85aeeeec;
    border: 1px solid #85aeeeec;
    // border: 1px solid #3165BA;
  }
  .DateRangePicker__Date--is-selected {
    // color: #fff;
    color: black;
  }
  .DateRangePicker__CalendarSelection--is-pending {
    // background-color: #3165BA;
    background-color: #85aeeeec;
    // border-bottom-left-radius: 10px;
    // border-top-left-radius: 10px;
  }

  // .DateRangePicker__Date {
  //   padding: 14px 0px !important;
  // }
  // .DateRangePicker__CalendarSelection {
  //   background: #3262b18f;
  //   border: 1px solid #3262b18f;
  // }
  // .DateRangePicker__CalendarSelection--is-pending {
  //   background: #3262b18f;
  // }
  // .DateRangePicker__CalendarHighlight--single {
  //   &:hover {
  //     border-radius: 30px !important;
  //   }
  // }
  // .DateRangePicker__CalendarSelection--start,
  // .DateRangePicker__CalendarSelection--end {
  //   border-radius: 30px !important;
  //   background-color: $medium-blue;
  //   color: white !important;
  // }
}
.inputdateRangePicker-body.open {
  display: block;
}

.inputdateRangePicker-item {
  padding: 10px;
}

.inputdateRangePicker-item:hover {
  cursor: pointer;
}

.inputdateRangePicker-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.inputdateRangePicker-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

@media only screen and (min-width: 992px) {
  .inputdateRangePicker-body {
    width: 645px;
    right: -6px;
  }
}
