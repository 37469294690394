.help-header {
  font-size: 34px !important;

  @media only screen and (max-width: 480px) {
    font-size: 22px !important;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 28px !important;
  }

  @media only screen and (min-width: 769px) and (max-width:1024px) {
    font-size: 32px !important;
  }
}

.help-text {
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;

  }

  @media only screen and (min-width: 769px) and (max-width:1024px) {
    font-size: 15px;
  }
}

.help-secondary-header {
  font-size: 22px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 17px;
  }
}

.help-secondary-header_1 {
  font-size: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: 20px;
  }
}

.help-form-text {
  font-size: 15px;

  @media only screen and (max-width: 991px) {
    font-size: 13px;

  }
}

.help-form-text-label {
  font-size: 16px;

  @media only screen and (max-width: 991px) {
    font-size: 14px;

  }
}

.help-send-button {
  .btn-sm {
    font-size: 15px;
    font-weight: 500;

    @media only screen and (max-width: 540px) {
      font-size: 13.5px;

    }
  }
}