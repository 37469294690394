.admin-filter-datepicker {
  // margin-top: 10px;
  .DateRangePicker__Month {
    // width: 260px;
    // border: solid 0.5px #dcdcdcba !important;
    // border-radius: 4px;
    padding: 0px !important;
    // margin: 0px 10px 0px 10px !important;
    font-size: 11px !important;
  }

  .DateRangePicker__MonthHeader {
    font-size: 12px;
    height: 25px;
    line-height: 35px;
  }

  .DateRangePicker__WeekdayHeading,
  .DateRangePicker__Date {
    font-size: 11.5px;
  }

  .DateRangePicker__CalendarHighlight--single {
    border: 1px solid #85aeeeec !important;
  }

  .DateRangePicker__CalendarSelection {
    background-color: #85aeeeec;
    border: 1px solid #85aeeeec;
  }

  .DateRangePicker__CalendarSelection--is-pending {
    background-color: #85aeeeec;
  }

  .DateRangePicker__Date--is-selected {
    color: black;
  }

  @media (max-width: 1199px) {
    .DateRangePicker__Month {
      width: 220px !important;
      padding: 0px !important;
      font-size: 11px !important;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1399px) {
    .DateRangePicker__Month {
      width: 260px;
      padding: 0px !important;
      font-size: 11px !important;
    }
  }

  @media (min-width: 1400px) and (max-width: 1550px) {
    .DateRangePicker__Month {
      width: 201px;
      padding: 0px !important;
      font-size: 11px !important;
    }
  }
}

.admin-filter-datepicker-input {
  min-height: 31px;
  // max-height: 33;
  overflow: hidden;
}

.admin-filter-datepicker-dropdown {
  box-shadow: -2px 3px 10px rgba(82, 80, 80, 0.17);
  background-clip: padding-box !important;
  border: 1px solid rgba(82, 80, 80, 0.17) !important;
  border-radius: 0.25rem !important;
  margin-top: 4px;
  position: absolute;
  z-index: 4;
  font-size: 11px;
  // width: fit-content !important;
  overflow-x: scroll;
  // scrollbar-width: 0 !important;

  .data-row:hover {
    background-color: #3165ba;
    color: #ffffff !important;
  }
}

.admin-filter-datepicker-dropdown_our {
  box-shadow: -2px 3px 10px rgba(82, 80, 80, 0.17);
  background-clip: padding-box !important;
  // border: 1px solid rgba(82, 80, 80, 0.17) !important;
  // border-radius: 0.25rem;
  border-bottom-right-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important;
  // margin-top: 4px;
  position: absolute;
  z-index: 4;
  font-size: 12px;
  // width: fit-content !important;
  // overflow-x: scroll;
  // scrollbar-width: 0 !important;

  .data-row:hover {
    background-color: #3165ba;
    color: #ffffff !important;
  }
}

.datepickerWidth {
  max-width: 18%;
  min-width: auto;
}

@media (min-width: 1440px) and (max-width: 1660px) {
  .datepickerWidth {
    max-width: 21%;
    min-width: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .datepickerWidth {
    max-width: 25%;
    min-width: auto;
  }
}

@media (max-width: 1200px) {
  .datepickerWidth {
    max-width: 40% !important;
    min-width: auto;
  }
}

@media screen {
}
