.reset-password__container {
  margin-top: 100px;

  .card {
    width: 60%;
  }


  @media only screen and (max-width: 767px) {
    .card {
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width:992px) {
    .card {
      width: 80%;
    }
  }
}