// .accordion-button {
//   color: white;
//   // background-color: #3165BA;
//   -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
//   box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
//   font-size: 14px !important;
// }
.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg + xml,
 %3csvgxmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23212529" %3e%3cpathfill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg + xml,
 %3csvgxmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%230c63e4" %3e%3cpathfill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25remrgba (13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #e40c0c;
  --bs-accordion-active-bg: #ffff !important;
}

.button-booking,
.button-booking1 {

  // .accordion-button {
  //   font-weight: 500;
  // }
  .accordion-button:not(.collapsed) {
    color: white !important;
    background-color: #3165ba !important;
    -webkit-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    font-weight: 500;
  }

  .accordion-button::after {
    background-image: url("/images/indicator-arrow.png") !important;
    transform: rotate(0deg) !important;
  }
}

.range-add-list {
  .slider label {
    color: #888;
    font-size: 0.8rem;
    margin-bottom: 20px;
    display: block;
    text-transform: capitalize;
  }

  .input-range__slider {
    background: #ccad2f;
    border: none;
    top: -2px;
  }

  .input-range__track {
    background: #ddd;
    height: 9px;
  }

  .input-range__track--active {
    background: #ccad2f;
    height: 9px !important;
  }

  .input-range__label--value .input-range__label-container {
    background: "";
    color: #5e5e5e;
    font-size: 1rem;
    padding: 2px 5px;
    border-radius: 2px;
    margin-top: -2500px;
    top: 40px;
  }

  .input-range__label--min .input-range__label-container,
  .input-range__label--max .input-range__label-container {
    font-size: 0rem;
    color: #888;
    left: 0;
  }

  .input-range__label--max .input-range__label-container {
    left: 25%;
  }
}

// Scroll
.scroll {
  max-height: 70px;
  border: 1px solid #ddd;
  display: flex;
  overflow-x: auto;
}

.scroll::-webkit-scrollbar {
  width: 0;
}

.scroll1 {
  min-width: 300px;
  // height: 100px;
  // line-height: 110px;
  text-align: center;
  background-color: #ddd;
}

.modal-lefside {
  z-index: 100 !important;
}

.mov-div {
  margin-left: -20px;
}

.mov-horizontal {
  margin-top: -1000px;
}

.addlicense-button {
  margin-left: -30px;
}

// Request-reservation-modal
.request-modal {
  top: -0px;
  position: relative;
}

.request-modal-lg {
  top: -0px;
  position: relative;
}

// 1024 px
.media-small {
  @media only screen and (max-width: 1024px) {
    margin-left: -50px;
  }
}

//Add-More-Button-Add-List
.add-more {
  margin-left: -15px;
}

.mov-file {
  margin-left: -10px;
}

// Radio Add-list
.welcome-bar {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 3px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #E7E6E7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

//
.welcome-bar1 {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 14px;
    height: 14px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #E7E6E7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

.booking_datepicker1 {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 5px;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 13px;
    // font-style: bold;
    // font-weight: bold;
    width: 45rem;
    padding: 6px;
    color: black !important;
    color: black;
    font-weight: 500;
  }
}

.booking_datepicker2 {
  .react-date-picker__wrapper {
    border: 1px solid #ccc !important;
    border-radius: 5px;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 13px;
    font-style: bold;
    font-weight: bold;

    padding: 6px;

    @media only screen and (max-width: 320px) {
      width: 14rem !important;
    }

    @media only screen and (max-width: 375px) and (min-width: 321px) {
      width: 17.2rem !important;
    }

    @media only screen and (max-width: 375px) and (min-width: 321px) {
      width: 17.2rem !important;
    }

    @media only screen and (max-width: 425px) and (min-width: 376px) {
      width: 20.5rem !important;
    }
  }
}

.booking_datepicker3 {
  .react-date-picker__wrapper {
    // border: 1px solid #ccc !important;
    // border-radius: 5px;
    border: none;
    background-color: white;
  }

  .react-date-picker__inputGroup {
    font-size: 13px;
    // font-style: bold;
    // font-weight: bold;

    // padding: 6px;
    // @media only screen and (max-width: 320px) {
    //   width: 14rem !important;
    // }
    // @media only screen and (max-width: 375px) and (min-width: 321px) {
    //   width: 17.2rem !important;
    // }
    // @media only screen and (max-width: 375px) and (min-width: 321px) {
    //   width: 17.2rem !important;
    // }
    // @media only screen and (max-width: 425px) and (min-width: 376px) {
    //   width: 20.5rem !important;
    // }
  }
}

// Type-file

.welcome-file {
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  .custom-file-input::before {
    content: "Select some files" !important;
    display: inline-block !important;
    background: linear-gradient(to right, #f9f9f9, #e3e3e3) !important;
    border: 1px solid #999 !important;
    border-radius: 3px !important;
    padding: 5px 8px !important;
    outline: none !important;
    white-space: nowrap !important;
    -webkit-user-select: none !important;
    cursor: pointer !important;
    text-shadow: 1px 1px #fff !important;
    font-weight: 700;
    font-size: 10pt;
  }

  .custom-file-input:hover::before {
    border-color: black;
  }

  .custom-file-input:active::before {
    background: -webkit-linear-gradient(to right, #e3e3e3, #f9f9f9);
  }
}

.accordionopacity {
  .accordion-button {
    opacity: 0.5 !important;
  }
}