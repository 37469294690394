//Custom bootstrap overides and variables
@import "../styles/vendor/mixins";
@import "../styles/vendor/custom";

//reset,fonts
@import "../styles/base";
//Pages
@import "../styles/pages";
//global
@import "../styles/utils/global.scss";

//UI components
@import "../styles/components/UI";
@import "../styles/components";

// Front-end
@import "../styles/pages/front-end";
@import "../styles/pages/school";
@import "../styles/components/checkbox";

@import "../styles/components/UI/info_popup.scss";

@import "../styles//components/radio";

@import "react-input-range/lib/css/index.css";
@import "react-tabs/style/react-tabs.css";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap/dist/css/bootstrap.css";
