// ---------------------------------------------------------------------

/* Popup container - can be anything you want */
.popup {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid rgba(204, 204, 204, 0.624);
  border-radius: 5px;
  margin-top: 5px;
  z-index: 99999;
  box-shadow: 0px 1px 5px rgba(204, 204, 204, 0.624);
  // padding: 20px;
  background-color: white;
   visibility: visible;
  // -webkit-animation: fadeIn 0.7s;
  // animation: fadeIn 0.7s;
  
}

.popup_arrow ::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(85, 85, 85, 0.879) transparent transparent transparent;
}

/* Add animation (fade in the popup) */
// @-webkit-keyframes fadeIn {
//   from {opacity: 0;} 
//   to {opacity: 1;}
// }

// @keyframes fadeIn {
//   from {opacity: 0;}
//   to {opacity:1 ;}
// }

// --------------------------------------------------------------------------------
