.instructor-file-button {
  ::-webkit-file-upload-button:after {
    visibility: hidden;
    content: "Browse File";
    background: #3165ba;
    color: white;
    //padding: 1em;
    border-radius: 3px;
    border: none;
    font-size: 11px;
    padding: 8px;
  }
  input[type="file"] {
    font-size: 11px;
  }
}

.instructor-scrollabel {
  -webkit-scrollbar {
    display: none !important;
  }
  overflow-y: scroll;
  overflow-x: hidden;
  height: 23rem;
  padding-right: 2rem;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
}

.instructor-scrollabel::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
