.fw-500{
    font-weight: 500 !important;
}

.fw-700{
    font-weight: 700 !important;
}
.fs-16{
    font-size: 16px !important;
}
.fs-18{
    font-size: 18px !important;
  }

.margin-top-11{
    margin-top: 11px;
}

.margin-top-star{
    margin-top: -6px;
}

.table-border{
    border:solid 0.5px #e3e7ed;
    border-radius: 5px;
}

.review-card-padding{
    .card-body{
        padding:10px;
    }
}