// custom variable values
$purple: #732873cf;
$primary: #3165ba;
$dark: #222222;
$black: #0000;
$title-black: #333;
$white: #f4f4f4;

$white-Four: #dcdcdc;
$light-navy: #16356a;
$medium-blue: #3165ba;
$light-navy-two: #113c84;
$dull-orange: #ccad2f;
$cyan: #0dcaf0;
$light: #dcdcdc;
//font size
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xs: ($font-size-base * 0.75);
$font-size-sm: ($font-size-base * 0.875);
$font-size-md: ($font-size-base * 1);
$font-size-lg: ($font-size-base * 1.25);
$font-size-xl: ($font-size-base * 1.5);
$font-size-xxl: ($font-size-base * 2);
$brownish-grey: #5e5e5e;

//radius
$border-radius: 12px;
$border-radius-large: 40px;

$border-width: 0.0625rem;

$spacer: 1rem;

$transition-base: all 0.2s ease;
$transition-transform: transform 0.2s ease;
