.certificate-badge-container {
  display: inline-block;
  position: relative;
}

.certificate-badge {
  position: absolute;
  z-index: 2;
  bottom: -8px;
  right: -5px;
}

.badge-free-gift {
  position: absolute;
  z-index: 2;
  background: #bfa22c;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 95px;
  padding-top: 4px;
  padding-bottom: 4px;
  // padding-right: 4px;
  top: 10px;
  left: -20px;
  transform: rotate(320deg);
}

.badge-popular {
  position: absolute;
  z-index: 2;
  background: #e89907;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 95px;
  padding-top: 4px;
  padding-bottom: 4px;
  // padding-right: 4px;
  top: 10px;
  left: -20px;
  transform: rotate(320deg);
}

// =====================================
.badge-container {
  display: inline-block;
  position: relative;
  overflow: hidden;

  .badge {
    &.dull-oranage {
      color: white !important;
    }

    &.diagonal {
      white-space: nowrap;
      position: absolute;
      padding: 5px 100px;
      // min-width: 300px;
      transform: rotate(-45deg) translate(-37%, 0);
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      top: -2 0px;
      box-sizing: border-box;
    }
  }
}

// ---------------------------------

.box-borders {
  box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px,
    rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px,
    rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px,
    rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px,
    rgb(255, 85, 85) 40px -40px;
}

.unique-experience-image {
  // border-bottom-left-radius: 6px;
  // background-size: cover;
  // background-position: center center;
  // background-repeat: no-repeat;
  // min-width: 190px;
  // max-width: 190px;

  @media only screen and (max-width: 320px) {
    min-height: 180px !important;

  }

  @media only screen and (max-width: 375px) and (min-width: 321px) {
    min-height: 180px !important;
  }

  @media only screen and (max-width: 375px) and (min-width: 321px) {
    min-height: 180px !important;
  }

  @media only screen and (max-width: 425px) and (min-width: 376px) {
    min-height: 180px !important;
  }
}

.imageContainer img {
  transition: transform .3s ease-in-out;
  transform: scale(1);
  //  min-width: 190px;
  // max-width: 190px;
}

.imageContainer:hover img {
  transform: scale(1.1);
}

.imageContainer1 {
  transition: transform .3s ease-in-out;
  transform: scale(1);
  //  min-width: 190px;
  // max-width: 190px;
}

.imageContainer1:hover {
  transform: scale(1.1);
}

.language-currency {
  width: 23rem !important;
  margin-top: 5px !important;
  // margin-top: 0.8rem !important;
  padding: 0;

  @media only screen and (min-width: 769px) {
    left: -20rem !important;
  }
}

.language-currency .dropdown-item {
  background-color: transparent !important;
  padding: 0 !important;
}

.font-secondary {
  font-size: 13px !important;
  padding-right: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 13px !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 11px !important;
  }

  @include media-breakpoint-up(lg) {
    font-size: 11px !important;
  }
}

.customer-font {
  font-size: 0.855em !important;

  @media only screen and (max-width: 540px) {
    font-size: 15px !important;
    opacity: 0.8;
  }
}

.customer-title-font {
  font-size: 22px !important;
  color: #000000;

  @media only screen and (max-width: 540px) {
    font-size: 18px !important;
  }
}

.customer-secondary-font {
  font-size: 17px !important;
}

.email-img {
  @media only screen and (min-width: 541px) {
    height: 12px !important;
    width: 12px !important;
  }

  @media only screen and (max-width: 540px) {
    //margin-left: 1.5rem !important;
    height: 15px !important;
    //width: 12px !important;
    //margin-right: 1rem !important;
  }
}

.subscribe-button {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;

  @media screen and (max-width: 767px) {
    border-radius: 5px;
  }

}

.footer-log-mobile {
  height: 45px;
  width: auto;

  @media only screen and (max-width: 768px) {
    height: 55px;
    width: auto;
  }
}

.footer-text {
  font-size: 14px;
  line-height: 19px;

  @media only screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
  }

  @media only screen and (min-width: 992px) and (max-width:1024px) {
    font-size: 13px;

  }
}

.footer-social-icon {
  @media only screen and (min-width: 769px) {
    height: 30px;
    width: 30px;
  }
}

.copy-right-align {
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}

.product-card-header {
  font-size: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.search-button-width {
  @media only screen and (min-width: 768px) {
    width: 5rem !important;
  }
}



.scroll-bar {
  overflow-x: auto;
  white-space: nowrap;
}

.scroll-bar::-webkit-scrollbar {
  display: none;
}

.header-title {
  font-size: 46px;
  text-shadow: 1.5px 0 #979797;

  @media only screen and (max-width: 480px) {
    font-size: 34px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 39px;
  }

  @media only screen and (min-width: 769px) and (max-width:1024px) {
    font-size: 45px;
  }
}

.landing_header-title {
  font-size: 46px;
  text-shadow: 1.5px 0 #979797;

  @media only screen and (max-width: 480px) {
    margin-top: -80px;
    font-size: 34px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    margin-top: -90px;
    font-size: 39px;
  }

  @media only screen and (min-width: 769px) and (max-width:1024px) {
    font-size: 45px;
  }
}

.landingTextContainer {
  margin-top: 80px;
  margin-bottom: 15px;

  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    margin-top: 15px;
  }

}

.header-paragraph {
  font-size: 22px;
  text-shadow: 1px 0 #979797;

  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 769px) and (max-width:991px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 992px) and (max-width:1024px) {
    font-size: 20px;
  }
}

.textmobile {
  font-size: 15px;

  @media only screen and (max-width: 427px) {
    font-size: 12.5px;
  }

  @media only screen and (min-width: 428px) and (max-width:530px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 531px) and (max-width:768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 768px) and (max-width:991px) {
    font-size: 13.5px;
  }
}

.feature-title {
  font-size: 34px !important;

  @media only screen and (max-width: 480px) {
    font-size: 22px !important;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 28px !important;
  }

  @media only screen and (min-width: 769px) and (max-width:1024px) {
    font-size: 32px !important;
  }
}

.feature-title_1 {
  font-size: 34px;

  @media only screen and (max-width: 480px) {
    font-size: 22px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 28px;
  }

  @media only screen and (min-width: 768px) and (max-width:1024px) {
    font-size: 32px;
  }


}

.feature-paragraph {
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 769px) and (max-width:1024px) {
    font-size: 15px;
  }
}

.partner-paragraph {
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 481px) and (max-width:768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 769px) and (max-width:1024px) {
    font-size: 15px;
  }
}

.subscribe-email-text {
  font-size: 12px !important;

  @media only screen and (max-width: 540px) {
    font-size: 14px !important;
  }
}

.subscribe-email-input {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;

  @media screen and (max-width: 767px) {
    border-radius: 5px;
  }
}

.dashedBorder {
  border-right: 2px dashed #fff;

  @media only screen and (max-width: 540px) {
    border-right: none;
  }

  @media only screen and (max-width: 767px) {
    border-right: none;
  }

}

.BottomBorder {


  @media only screen and (max-width: 767px) {
    border-bottom: 2px dashed #fff;
    padding-bottom: 5px;
  }
}