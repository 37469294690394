button-filter {
  border: 1px #5e5e5e;
  width: 70px;
  color: #5e5e5e;
}
button-filter:focus {
  background: #3165ba;
}
button-filter:active {
  background: #3165ba;
}
.package-options {
  // padding: 0.4rem 0.2rem;

  .package-options__header {
    display: flex;

    .package-options__name {
      font-size: 0.8rem;
      font-weight: bold;
    }
    .package-options__price {
      font-size: 0.8rem;
      color: #3165ba;
      font-weight: bold;
    }
  }
  .package-options__details {
    font-size: 0.8rem !important;
    margin-bottom: 0 !important;
    font-weight: 500;
  }
}

.packageSelect{
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 12px;
  }
}

.range_slider--booking-policy {
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    margin-right: 10px;
    border-radius: 6px;
    outline: 0;
    background: #ccc;
  }
  &::-webkit-slider-runnable-track {
    background: orange;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    background: orange;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
  }

  /* All the same stuff for Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    background: orange;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb {
    height: 18px;
    width: 18px;
    border-radius: 3px;
    background: orange;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
  }
}
