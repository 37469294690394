.requestreserv-header {
  font-size: 14px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;

  }
}

.requestreserv-fs-12 {
  font-size: 12px !important;

  @media only screen and (max-width: 768px) {
    font-size: 14px !important;

  }
}

.requestreserv-fs-11 {
  font-size: 11px;

  @media only screen and (max-width: 768px) {
    font-size: 13px;

  }
}


.requestreserv-fs-16 {
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;

  }

  @media only screen and (min-width: 769px) and (max-width: 992px) {
    font-size: 15px;

  }
}

.star {
  height: 13px;
  width: 13px;
  margin-top: 6px;
  margin-left: 3px;

  @media only screen and (max-width: 768px) {
    height: 16px;
    width: 16px;
    margin-top: 6px;
    margin-left: 3px;

  }
}

.star_1 {
  height: 16px;
  width: 16px;
  margin-left: 3px;
}