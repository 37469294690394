.school-booking-list {
}

.fw-400 {
  font-weight: 400;
}

.light-navy {
  color: #3165ba;
}

.text-navy-blue {
  color: #113c84;
}

.pagination-letter-spacing {
  letter-spacing: 0.32px;
  color: rgba(43, 43, 43, 0.8);
}

.profile-padding {
  padding-left: 3rem;
}

.booking-profile {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
}

.school-booking_policy_modal {
  .modal-content {
    width: 75% !important;
    max-width: none !important;
    margin-top: 20%;
    margin-left: 15%;
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer {
  color: #5e5e5e !important;
}

.steps-li {
  ul {
    list-style: none;
    //margin:0;
    padding: 0;
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      width: 1px;
      background: #dcdcdc;
      position: absolute;
      left: 3px;
      top: 5px;
      height: calc(100% - 38px);
    }
    // li > div {
    //     display: inline-block;
    //     border: 2px solid #ccc;
    //     border-radius: 100%;
    //     width: 25px;
    //     height: 25px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-around;
    //     margin-right: 10px;
    //   }
    li {
      position: relative;
      padding-left: 15px;
      margin-bottom: 15px;

      &:before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        // background-color: #ccad2f;
        position: absolute;
        left: 0;
        top: 5px;
        border-radius: 50%;
        // border: 1px solid #ccad2f; /* inner border */
        // box-shadow: 0px 0px 0px 0.5px #ccad2f; /* outer 'border' */
        // outline: 5px solid white; /* fill */
      }
    }
    .steps-completed {
      &:before {
        background-color: #ccad2f;
      }
    }
    .steps-incompleted {
      &:before {
        background-color: #dcdcdc;
      }
    }
    .steps-active {
      &:before {
        background-color: #ccad2f;
        animation: glow 1s infinite alternate;

        @keyframes glow {
          from {
            box-shadow: 0 0 6px -6px #e9d481e6;
          }
          to {
            box-shadow: 0 0 6px 6px #e9d481e6;
          }
        }
      }
    }
  }
}

.disabled-steps {
  opacity: 0.4;
}

//   .circle {
//     background:#FF66CC;
//     width: 35px;
//     height: 35px;
//     border-radius: 50%;
//     box-shadow: 0px 0px 1px 1px #0000001a;

//     &::before,
// 	&::after {
// 		content: '';

// 		position: absolute;
// 		top: 50px;
// 		right: 50px;
// 		bottom: 50px;
// 		left: 50px;
// 		z-index: -1;

// 		border-width: 1px;
// 		border-style: solid;
// 		border-color: currentColor;
// 		border-radius: 50%;

// 		will-change: auto;
// 		animation-name: pulse-ring;
// 		animation-duration: 2s;
// 		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
// 		animation-iteration-count: infinite;
// 	}
// 	&::after {
// 		animation-delay: 300ms;
// 	}

//   }

//   @keyframes pulse-ring {
// 	0% {
// 		transform: scale(0.5);
// 	}
// 	60% {
// 		opacity: 0;
// 	}
// 	70% {
// 		transform: scale(1);
// 		opacity: 0;
// 	}
// 	100% {
// 		transform: scale(1);
// 		opacity: 0;
// 	}
// }
