.placeholder-partner::placeholder {
  color: black !important;
}
.partner-login {
  .modal-content {
    width: 80% !important;
    // right: 30%;
    left: 10%;
  }

  @media only screen and (max-width: 540px) {
    .modal-content{
      width: 100% !important;
      left:0%
    }
  }
  //   margin-top: 10%;
}
