.react-selecttick {
  font-size: 12px !important;

  .css-1okebmr-indicatorSeparator {
    background-color: white !important;
    // display: flex;
  }
}

.roundedbordertake1 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

// rouunder border bottom
.roundedbordertake2 {
  border-bottom-right-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
  border: 1px solid #dee2e6 !important;
  background-color: white;
}

.coupon-select {
  .css-9gakcf-option {
    background-color: white;
    color: black;
  }

  .css-1n7v3ny-option {
    background-color: white;
  }

  input[type="checkbox"] {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    background: white;
    height: 15px;
    width: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    position: relative;
    right: 5px;
    top: 3px;
  }

  input[type="checkbox"]:checked {
    background: #3165ba;
    border-radius: 2px;
    border: 1px solid #3165ba;
  }

  input[type="checkbox"]:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;

    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input[type="checkbox"]:checked:after {
    display: block;
  }
}

.date-img {
  @media only screen and (max-width: 320px) {
    margin-left: -9px !important;
  }

  @media only screen and (max-width: 375px) and (min-width: 321px) {
    margin-left: 11px !important;
  }
}
