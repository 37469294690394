.my-retreatfonts {
  font-family: AirbnbCerealApp;
  font-size: 7.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #010101;
}

// .dropdowntoggle {
//   .dropdown-toggle::after {
//     height: 0px;
//     width: 0px;
//     border: none;
//     border-top: 5px solid #000000;
//     border-left: 5px solid rgba(0, 0, 0, 0);
//     border-right: 5px solid rgba(0, 0, 0, 0);
//   }
// }
._my-listing_ {
  .dropdown-item:active {
    background-color: $medium-blue  !important;
  }

  .dropdown-item:hover {
    background-color: $medium-blue  !important;
    color: #fff;
  }

  .dropdown-menu {
    box-shadow: -2px 3px 8px rgba(82, 80, 80, 0.17);
    border: transparent;
    border-radius: 4px;
  }

  ._online_offline_active {
    border: 1px solid $medium-blue  !important;
    color: $medium-blue  !important;
    border-radius: 4px;
  }

  ._online_offline_ {
    border: 1px solid black !important;
    color: black !important;
    border-radius: 4px;
  }
}