.custom-radio {
  .form-check-input:checked[type="radio"] {
    // background-image: url();
  }

  .form-check-input:checked {
    background-color: #3165ba !important;
    border-color: #3165ba !important;
    border: 1px;
  }
}
