.dashboard_button {
  border: solid 0.5px #d7dbec;
  background-color: white;

  .btn-primary {
    background-color: white !important;
    // border: solid 0.5px #d7dbec !important;
    border: none;
    padding: 9px 5px !important;
    text-align: center;
    width: 4rem !important;
    font-size: 13px;
    font-weight: 600;
    color: #8e9096 !important;
    box-shadow: none !important;
  }
  .btn-new-primary {
    background-color: #3165ba !important;
    color: white !important;
  }
  .btn-primary:hover {
    background-color: #3165ba !important;
    color: white !important;
  }

  .btn-primary:active {
    background-color: #3165ba !important;
    color: white !important;
  }
}
.btn-primary-Hover {
  background-color: #3165ba !important;
  color: white !important;
}
.dashboard-card {
  background: white;
  //border: 1px solid #ccc;
  border-radius: 4px;
  // height: 7rem;
  // margin-left: 10px;
  // width: 20rem;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 4px;

  // @media only screen and (max-width:935px) {
  //     width: 17rem;
  // }

  @media only screen and (min-width: 1400px) and (max-width: 1600px) {
    // width: 17rem;
  }
}

.card-header-color {
  color: #5a607f;
}

.font-green {
  color: #3dd598;
}

.font-red {
  color: #f0142f;
}

.image-circle {
  height: 50px;
  width: 50px;
  background-color: rgba(0, 88, 255, 0.1);
  border-radius: 50%;
  align-items: center;
}

.dashboard-chart {
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 4px;
}
