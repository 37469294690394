.social-icon {
  height: 40px !important;
  // margin-top: -8px !important;

  @include media-breakpoint-up(sm) {
    height: 30px !important;
    margin-top: 0px !important;

  }

  @include media-breakpoint-up(md) {
    height: 30px !important;

  }

  @include media-breakpoint-up(lg) {
    height: 30px !important;

  }
}

.social-icon-fb {
  height: 30px !important;

  @include media-breakpoint-up(sm) {
    height: 30px !important;
  }

  @include media-breakpoint-up(md) {
    height: 24px !important;

  }

  @include media-breakpoint-up(lg) {
    height: 24px !important;

  }
}