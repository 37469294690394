// DropDown
.dropdown-retreat {
  background-color: #ffff !important;
  min-width: 10em !important;
  .dropdown-menu {
    position: absolute !important;
    margin: 0 !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(55, 140, 39, 0.15) !important;
    border-radius: 0.25rem !important;
    min-width: 320px;
  }
  .dropdown-item {
    border: 1 px solid rgba(55, 140, 39, 0.15) !important;
    border-radius: 0.25rem !important;
  }
}
