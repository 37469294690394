.quillPlaceholder {
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 11px;
    color: gray;
    font-style: normal;
    opacity: 0.5;
    border-radius: 0.3rem !important;
  }
  .ql-container {
    // border: 1px solid #80bdff !important;
    // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    // border-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    min-height: 100px;
  }
  .ql-toolbar.ql-snow {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    opacity: 1;
  }
}
.quillPlaceholderCleanandSafeMeasures {
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 11px;
    color: gray;
    font-style: normal;
    font-weight: bold;

    // opacity: 0;
  }
}

.quillbasicdetails {
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 11px;
    color: black;
    font-style: normal;
    // font-weight: bold;

    // opacity: 0;
  }
  .ql-container {
    // border: 1px solid #80bdff !important;
    // box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    // border-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .ql-toolbar.ql-snow {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    opacity: 1;
  }
}

// add-list file
.settings-background1 {
  background: white;
  border-radius: 6px;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.custom-file-input1 {
  color: #5e5e5e;
}
.custom-file-input1::-webkit-file-upload-button {
  background-color: blue;
  border-radius: 5px;
  border: 2px solid #ccc;
  padding: 5px 10px;
  margin-right: 1rem;
  color: white;
}

.margin-left-image1 {
  margin-left: 1rem;
}

.setting_input-group1 {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  background: #ebf2fe !important;
  width: 3rem;
  justify-content: center;
}

.preview-border1 {
  max-width: 35rem;
  max-height: 40px;
  background-color: #3165ba;
  color: white;
  //margin: 10px 27.5px 336px 26.5px;
  padding: 10px 160px 6.5px;
}

.welcome-bar3 {
  input[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 2px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #bbb;
    //ackground-color: #e7e6e7;
    border-radius: 50%;
  }

  /* appearance for checked radiobutton */
  input[type="radio"]:checked {
    background-color: #3165ba;
    border: 1px solid #3165ba;
  }
}

.allpayemnt1 {
  .jexnEe {
    font-size: 11px;
    margin-top: 3px;
  }
}

.langauage-setting-footer1 {
  margin-top: 18rem;
}

.__messages_quill__ {
  .quill {
    display: flex;
    flex-direction: column-reverse;
  }
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 12px;
    font-style: normal;
  }
  .ql-container {
    min-height: 100px;
    border: 1px solid #ccc !important;
    border-radius: 0.25rem !important;
  }

  .ql-toolbar.ql-snow {
    border: none !important;
    opacity: 1;
    display: flex;
    justify-content: end;
    padding: 8px 0px !important;
  }

  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px !important;
  }
}
