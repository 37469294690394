.placeholder-signup::placeholder {
  color: black !important;
}

.modal-signup {
  .modal-content {
    width: 80% !important;
    // right: 30%;
    left: 10%;
  }

  //   margin-top: 10%;

  @media only screen and (max-width: 540px) {
    .modal-content {
      width: 100% !important;
      left: 0%
    }
  }
}

.signup-header {
  font-size: 16px !important;

  @media only screen and (max-width: 540px) {
    font-size: 14px !important;
  }
}

.signup-text {
  font-size: 13px !important;

  @media only screen and (max-width: 540px) {
    font-size: 12px !important;
  }
}