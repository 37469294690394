.image-crop-custom{
    .ReactCrop__image{
        // width: 16rem;
        // height: 12rem;
    }
    
}

.progressbar-animated{
    .progress-bar{
    background-color: green !important;
    }
}